/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

const Product = () => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
      <div className="col-6">
        <div className="fv-row mb-7">
          <label className="fs-6 fw-semibold form-label mt-3">
            <span className="">Product Name</span>
          </label>
          <input
            type="email"
            className="form-control form-control-solid"
            name=""
          ></input>
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-6">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mt-3">
                <span>QTY</span>
              </label>
              <input
                type="text"
                className="form-control form-control-solid"
                name=""
              ></input>
            </div>
          </div>
          <div className="col-6">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mt-3">
                <span>Price</span>
              </label>
              <input
                type="text"
                className="form-control form-control-solid"
                name=""
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SendInvoice: FC = () => {
  const [ProductDetails, setProduct] = useState([]);

  const onAddBtnClick = (event) => {
    setProduct(ProductDetails.concat(<Product key={ProductDetails.length} />));
  };

  return (
    <div className="modal fade" id="kt_modal_send_invoice" aria-hidden="true">
      <div className="modal-dialog mw-900px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-left ">
              <h1 className="mb-3">Invoice</h1>
            </div>

            <div className="separator d-flex flex-center mb-8"></div>

            <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
              <div className="col">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mt-3">
                    <span className="">Sales Order</span>
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-solid"
                    name="email"
                  ></input>
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
              </div>
              <div className="col">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mt-3">
                    <span>Sales Order Date</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="phone"
                  ></input>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <div className="d-flex flex-column mb-5 fv-row">
                <label className="fs-5 fw-bold  form-label mt-3 mb-2">
                  Recipient
                </label>
                <input
                  className="form-control form-control-solid"
                  placeholder="Name, username and number"
                  name="website"
                ></input>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
              <div className="col-6">
                <div className="fv-row mb-7">
                  <label className="fs-6 fw-semibold form-label mt-3">
                    <span className="">Product Name</span>
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-solid"
                    name=""
                  ></input>
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span>QTY</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        name=""
                      ></input>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span>Price</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        name=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {ProductDetails}
            <a
              href="#"
              className="btn btn-primary me-2 mb-2"
              onClick={onAddBtnClick}
            >
              <i className="las la-plus fs-2 me-2"></i>Add Product
            </a>

            <div className="card card-flush py-4 flex-row-fluid border rounded">
              <div className="card-body py-0">
                <div className="table-responsive">
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold">
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="fw-semibold">Subtotal</span>
                          </div>
                        </td>
                        <td className="fw-bold text-end">150.00</td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="fw-semibold">Discount</span>
                          </div>
                        </td>
                        <td className="fw-bold text-end">
                          <a href="#" className="btn btn-primary me-2 mb-2 p-3">
                            <i className="las la-plus fs-2 me-2"></i>Add
                            Discount
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="fw-semibold">Shipping Fee</span>
                          </div>
                        </td>
                        <td className="fw-bold text-end">
                          <a href="#" className="btn btn-primary me-2 mb-2 p-3">
                            <i className="las la-plus fs-2 me-2"></i>Add
                            Shipping
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <div className="d-flex align-items-center">
                            <span className="fw-bold">Subtotal</span>
                          </div>
                        </td>
                        <td className="fw-bold text-end">150.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mb-0">
              <label className="form-label fs-6 fw-bold text-gray-700">
                Message
              </label>
              <textarea
                name="notes"
                className="form-control form-control-solid"
                placeholder="Message"
              ></textarea>
            </div>
            <br></br>

            <div className="text-center">
              <button
                type="reset"
                id="kt_modal_new_target_cancel"
                className="btn btn-light-primary me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                id="kt_modal_new_target_submit"
                className="btn btn-primary"
              >
                <span className="indicator-label">Send</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SendInvoice };
