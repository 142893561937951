import React, { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface SignUp {
  ownerName: string;
  businessName: string;
  secDTI: string;
  link: string;
  address: string;
  number: string;
  emailAddress: string;
  category: string;
  idNumber: string;
}

const inits: SignUp = {
  ownerName: "",
  businessName: "",
  secDTI: "",
  link: "",
  address: "",
  number: "",
  emailAddress: "",
  category: "",
  idNumber: "",
};

const createAppSchema = [
  Yup.object({
    ownerName: Yup.string().required().label("This"),
    businessName: Yup.string().required().label("This"),
    secDTI: Yup.string().required().label("This"),
    link: Yup.string().required().label("This"),
    address: Yup.string().required().label("This"),
    number: Yup.string().required().label("This"),
    emailAddress: Yup.string().required().label("This"),
    category: Yup.string().required().label("This"),
    idNumber: Yup.string().required().label("This"),
  }),
];
const submitStep = (values: SignUp, actions: FormikValues) => {
  alert(1);
};

export function SignUp() {
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [initValues] = useState<SignUp>(inits);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/USAPBIZ/Images/login-group.png"
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
            className="h-100px"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-600px bg-white rounded  p-10 p-lg-15 mx-auto mb-20">
          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
          >
            {() => (
              <Form className="form w-100" noValidate id="">
                {/* begin::Heading */}
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3">
                    Sign Up as a Business Owner
                  </h1>
                  <div className="text-gray-400 fw-bold fs-7">
                    First, create your Business USAP account and fill up the
                    details below.
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> Business Owner Name </span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Business Owner Name"
                        name="ownerName"
                      />

                      <div className="text-danger">
                        <ErrorMessage name="ownerName" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> Business Name </span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Business Name "
                        name="businessName"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="businessName" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> SEC or DTI No. </span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="SEC or DTI No."
                        name="secDTI"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="secDTI" />
                      </div>
                    </div>
                  </div>
                </div>

                <label className="form-check form-check-custom form-check-solid me-10 pb-3">
                  <input
                    className="form-check-input h-15px w-15px"
                    type="checkbox"
                    checked
                    required
                  ></input>
                  <span className="form-check-label fs-6 text-gray-700 ps-3">
                    I do not have business permit.
                  </span>
                </label>

                <div className="notice d-flex bg-light-primary border-primary border border-0 mb-4 p-6">
                  <i className="fas fa-exclamation-circle text-primary ms-2 fs-7 mt-1 me-1"></i>
                  <div className="fs-6 text-gray-700 pe-7">
                    Since you don't have any business permits, we will require
                    you to link your business social media accounts.
                  </div>
                </div>

                <div className="row">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-semibold form-label mt-3">
                      <span className="">Link</span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Link"
                      name="link"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="link" />
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-sm bg-light-primary w-100 mb-5 fs-7"
                >
                  <span className="indicator-label text-gray-700">
                    <i className="las la-plus fs-2 me-2"></i>
                    Add more social media
                  </span>
                </button>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> Business Complete Address </span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Business Complete Address"
                        name="address"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="address" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                  <div className="col">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className="">Mobile Number</span>
                      </label>
                      <Field
                        type="number"
                        className="form-control"
                        placeholder="+63"
                        name="number"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="number" />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className="">Email Address</span>
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        placeholder="@gmail.com"
                        name="emailAddress"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="emailAddress" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> ID Category </span>
                      </label>
                      <select
                        className="form-select text-gray-700"
                        name="category"
                      >
                        <option>Select ID Category</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                      {/* <input type="text" className="form-control" name="" value="" placeholder='Select ID Category'></input> */}
                      <div className="text-danger">
                        <ErrorMessage name="category" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className=""> ID Number </span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="ID Number"
                        name="idNumber"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="idNumber" />
                      </div>
                    </div>
                  </div>
                </div>

                <label className="fs-6 fw-semibold form-label mt-3">
                  <span className=""> Selfie with ID Photo </span>
                </label>
                <div className="text-gray-400 fw-bold fs-7 mb-5">
                  Selfie of you holding a valid, government-issued ID. Your head
                  must be in the middle of photograph.
                </div>
                <div className="row g-6 g-xl-9 mb-12">
                  <div className="col-12">
                    <div className="card h-100 flex-center border-primary border border-dashed p-8">
                      <img src="/media/icons/45010.svg" alt="" />
                      <p className="text-gray-400 fs-5 fw-bold mb-2 mt-2">
                        Take a selfie with your ID
                      </p>

                      {/* <div className="fs-7 fw-semibold text-gray-400">Drag and drop files here</div> */}
                    </div>
                  </div>
                </div>

                <div className="fv-row mb-10">
                  <div className="text-center">
                    <div className="d-flex">
                      <button
                        type="submit"
                        id="kt_modal_new_target_submit"
                        className="m-auto d-block btn btn-primary"
                      >
                        <span className="indicator-label">Sign Up</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      {/* <a href="/product-receipt" className="m-auto btn btn-primary me-2 mb-2 mt-10 px-10" >
                  Proceed
                </a> */}
                    </div>
                  </div>
                  <div className="text-center">
                    <span className="text-gray-400">
                      Already have an account?{" "}
                    </span>
                    <Link
                      to="/auth/login"
                      className="link-primary fs-7 fw-bolder btn-flex"
                      style={{ textAlign: "right" }}
                    >
                      {" "}
                      Sign In
                    </Link>
                  </div>

                  {/* end::Link */}
                </div>
                {/* end::Form group */}

                {/* begin::Action */}

                {/* end::Action */}
              </Form>
            )}
          </Formik>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
}
