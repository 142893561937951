import clsx from "clsx";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useLayout } from "../../../../../_metronic/layout/core";
import { AsideDefault } from "../../../../../_metronic/layout/components/aside/AsideDefault";
const AuthHeader: FC = () => {
  const [loading, setLoading] = useState(false);
  const { config, classes, attributes } = useLayout();
  const { header } = config;

  const [nav, setNav] = useState(true);

  const closeNav = () => {
    setNav(true);
  };
  const openNav = () => {
    setNav(false);
  };

  const DrawerButton = ({ onClick }) => (
    <div
      className="DrawerButton_Container noSelect d-lg-none d-md-none d-xs"
      onClick={onClick}>
      <KTSVG
        path={"/media/icons/duotune/abstract/abs015.svg"}
        className={"svg-icon-muted svg-icon-2hx"}
      />
    </div>
  );

  const DrawerContents = () => (
    <div className="DrawerContents__Container">
      <div className="menu-item">
        <Link
          to="/auth/sign-up"
          className="text-dark px-6 align-self-center text-nowrap me-3 mb-1 DrawerItem__Container menu-link without-sub">
          <span className="menu-title fs-4"> Register</span>
        </Link>
        <Link
          to="/auth/login"
          className="text-dark px-6 align-self-center text-nowrap me-3 mb-1 DrawerItem__Container menu-link without-sub">
          <span className="menu-title fs-4"> Login</span>
        </Link>
      </div>
    </div>
  );

  const Drawer = ({ isOpen }) => (
    <div
      className={`Drawer__Container ${
        isOpen ? "Drawer__Container--isOpen" : ""
      }`}>
      <div className=" pb-0 border-0 justify-content-start d-flex">
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={toggleDrawer}>
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      </div>
      <DrawerContents />
    </div>
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        className={clsx(
          "container-fluid align-items-stretch justify-content-between header-custom ",
          classes.header.join(" "),
          "align-items-stretch"
        )}
        {...attributes.headerMenu}>
        <div className="d-lg-none  d-xs">
          <div className="d-flex justify-content-center bd-highlight mb-3">
            <div className=""></div>
            <div className="">
              <Link to="/dashboard" className="logo-usap-sm">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/USAPBIZ/LOGO/HORIZONTAL/USAP-02.png"
                  )}
                  className="h-30px"
                />
              </Link>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "test",
            classes.headerContainer.join(" "),
            "d-flex align-items-stretch justify-content-between"
          )}>
          {/* begin::Wrapper */}
          <div className="d-xs-none w-100">
            <div className="d-flex justify-content-between bd-highlight mb-3">
              <div className=""></div>
              <div className="">
                <Link to="/dashboard" className="logo-usap-sm">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/USAPBIZ/LOGO/HORIZONTAL/USAP-02.png"
                    )}
                    className="h-30px"
                  />
                </Link>
              </div>
              <div className="d-flex align-items-stretch flex-shrink-0 menu-lg">
                {/* <Topbar /> */}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* end::Wrapper */}
        </div>
      </div>
      <Drawer isOpen={isOpen} />
      {/* <PageContents /> */}
    </>
  );
};

export { AuthHeader };
