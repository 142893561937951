/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthHeader } from "./header/AuthHeader";
import { database } from "../../../../firebase";
import { child, get, ref } from "firebase/database";

export function ProductPreview() {
  const CurrencyFormat = require("react-currency-format");
  const { product, reseller, quantity } =
    useParams<{ product: string; reseller: string; quantity: string }>();
  const [productDetails, setProductDetails] = useState<any>([]);
  const [productImage, setProductImage] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  // get the rtdb reference
  const databaseRef = ref(database);

  useEffect(() => {
    get(child(databaseRef, "products/active/" + product)).then(
      (snapshot): any => {
        // TODO: redirect to product not found page
        if (!snapshot.exists()) {
          alert("Product not found.");
        }
        // set the product details
        setProductDetails(snapshot.val());

        let totalPrice = (snapshot.val().price * parseInt(quantity)).toFixed(2);
        setTotalPrice(totalPrice);
        // we get the first entry in image object
        setProductImage(Object.values(snapshot.val().images)[0]);

        if (snapshot.val()?.quantity === 0) {
          window.history.back();
        }
      }
    );
  }, [product, reseller, quantity, databaseRef]);

  const renderSeeMore = () => {
    if (productDetails?.description?.length > 100) {
      return seeMore ? (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          ... See Less
        </a>
      ) : (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          ... See More
        </a>
      );
    }
    return productDetails.description;
  };
  return (
    <div>
      <AuthHeader />

      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed product-container">
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="w-lg-1000px bg-white rounded pt-0 mx-auto">
            <div className="row">
              <div className="col-md-8">
                <h1 className="mb-3 p-5">Products</h1>

                <div className="p-10" style={{ backgroundColor: `#FBFBFB` }}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="d-flex flex-center mt-9 mb-5">
                        <img
                          className="symbol m-auto"
                          src={productImage}
                          alt={productDetails.name}
                          width="180px"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="d-flex flex-column justify-content-center flex-row-fluid mb-5 mt-9">
                        {/* <div className="d-flex fs-6 fw-semibold align-items-center hidden">
                          <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3">
                            {productDetails.category}
                          </div>
                        </div> */}
                        <div className="d-flex fs-6 fw-semibold align-items-center mb-3">
                          <div
                            style={{ maxWidth: 200 }}
                            className="fs-4 fw-bold text-dark text-hover-primary text-dark lh-base"
                          >
                            {productDetails.name}
                          </div>
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center mb-3">
                          <div
                            className="fs-6 text-dark text-dark lh-base"
                            style={{ maxWidth: 400, whiteSpace: "pre-line" }}
                          >
                            {productDetails?.description?.length > 100 &&
                            seeMore
                              ? productDetails?.description
                              : `${productDetails?.description?.slice(
                                  0,
                                  100
                                )} `}
                            {renderSeeMore()}
                          </div>
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          <span className="text-primary pe-3 fs-6 text-center mt-5">
                            <CurrencyFormat
                              value={productDetails?.price?.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₱"}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h1 className="mb-3 p-5">Order Summary</h1>
                <div className="p-10" style={{ backgroundColor: `#FBFBFB` }}>
                  <div className="d-flex flex-wrap">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                      <tbody className="fw-semibold text-gray-600">
                        <tr>
                          <td className="py-0">
                            <div className="d-flex align-items-center">
                              <div className="ms-5">
                                <div className="fw-bold mt-5 text-dark">
                                  Item Price
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-end py-0">
                            <div className="fw-bold mt-5 text-dark">
                              <CurrencyFormat
                                value={productDetails?.price?.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₱"}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0">
                            <div className="d-flex align-items-center">
                              <div className="ms-5">
                                <div className="fw-bold mt-5 text-dark">
                                  Service Fee
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-end py-0">
                            <div className="fw-bold mt-5 text-dark">₱0.00</div>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0">
                            <div className="d-flex align-items-center">
                              <div className="ms-5">
                                <div className="fw-bold mt-5 text-dark">
                                  Quantity
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-end py-0">
                            <div className="fw-bold mt-5 text-dark">
                              {quantity}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0">
                            <div className="d-flex align-items-center">
                              <div className="ms-5">
                                <div className="fw-bold mt-5 text-dark">
                                  TOTAL
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-end py-0">
                            <div className="fw-bold mt-5 text-primary ">
                              <CurrencyFormat
                                value={totalPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₱"}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="m-auto">
                      <Link
                        to={
                          "/guest-checkout/" +
                          product +
                          "/" +
                          reseller +
                          "/" +
                          quantity
                        }
                        className="btn btn-primary me-2 mb-2 mt-10 px-10"
                      >
                        Proceed
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
}
