/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login, loginAsBusiness } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    //.email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});


const businessSchema = Yup.object().shape({
  business_id: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Business ID is required"),
  email: Yup.string()
    //.email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
  business_id: ""
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const [asFac, setAsFac] = useState(false);

  const toggle = () => {
    setAsFac(prev => !prev);
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: asFac ? businessSchema : loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if(asFac) {
        loginAsBusiness({username: values.email, password: values.password, business_id: values.business_id})
          .then(({ data }) => {
            setLoading(false);
            dispatch(auth.actions.loginAsBusiness(data.token, data.fc_uid));
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus("The login detail is incorrect");
          });
          
      } else {
        

        setTimeout(() => {
          login(values.email, values.password)
            .then(({ data: { token_details } }) => {
              setLoading(false);
              dispatch(auth.actions.login(token_details?.id_token));
            })
            .catch(() => {
              setLoading(false);
              setSubmitting(false);
              setStatus("The login detail is incorrect");
            });
        }, 1000);
      }
    },
  });
  
  const styles: { [key: string]: React.CSSProperties } = {
    dnone: {
      display: "none",
    },
    textRight: {
      textAlign: "right",
      display: "flex",
    },
  };

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/USAPBIZ/Images/login-group.png"
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-4">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
            className="h-80px"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px rounded  mx-auto">
          <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            {/* begin::Heading */}
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Log in</h1>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              <div
                className="mb-10 bg-light-info p-8 rounded"
                style={styles.dnone}
              >
                <div className="text-info">
                  Use account <strong>admin@demo.com</strong> and password{" "}
                  <strong>demo</strong> to continue.
                </div>
              </div>
            )}

            {/* begin::Form group */}
          { asFac && <div className="fv-row mb-4">
              <label className="form-label fs-6 fw-bolder text-dark">
                Business ID
              </label>
              <input
                placeholder="Business ID"
                {...formik.getFieldProps("business_id")}
                className={clsx(
                  "form-control form-control-lg ",
                  { "is-invalid": formik.touched.business_id && formik.errors.business_id },
                  {
                    "is-valid": formik.touched.business_id && !formik.errors.business_id,
                  }
                )}
                type="text"
                name="business_id"
                autoComplete="off"
              />
              {formik.touched.business_id && formik.errors.business_id && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.business_id}</span>
                </div>
              )}
            </div>}

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Username
              </label>
              <input
                placeholder="Username"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg ",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  {/* begin::Label */}
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type="password"
                placeholder="Password"
                autoComplete="off"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control form-control-lg ",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base  justify-content-space-between fw-semibold mt-5 mb-8">
            <div>
            <input
              className='form-check-input'
              type='checkbox'
              checked={asFac}
              onChange={toggle}
              name='notifications'
              defaultChecked={true}
              style={{marginRight: 8}}
            />
            <label style={{color: 'black'}} className='form-check-label link-primary fw-bolder'>Login as Facilitator</label>
            </div>
                <Link
                  to="/auth/forgot-password"
                  className="link-primary fs-7 fw-bolder btn-flex"
                >
                  Forgot Password ?
                </Link>
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className="indicator-label">Login</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
}
