/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
};

const WidgetWelcome: React.FC<Props> = ({ className }) => {
  return (
    <div className={` ${className}`}>
      <div className="notice d-flex bg-light-primary rounded border-primary border border-0 mb-9 p-6">
        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap p-5">
          <div className="mb-3 mb-md-0 fw-bold w-lg-500px">
            <h4 className="text-dark fw-bolder">
              Welcome, Crisha Mae
              <span>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/icons/hello-icon.png")}
                  className="h-20px ms-2 mb-1"
                />
              </span>
            </h4>
            <div className="fs-6 text-gray-700 pe-7">
              We want to know you more. Please fill out the remaining
              information that we need by clicking the button beside.
            </div>
          </div>
          <div>
            <a
              href="#"
              className="btn btn-outline btn-outline-solid btn-outline-primary btn-active-light-primary px-6 align-self-center text-nowrap me-3 mb-1"
            >
              Remind me later
            </a>
            <a
              href="#"
              className="btn btn-primary px-6 align-self-center text-nowrap  mb-1"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_two_factor_authentication"
            >
              Fill Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WidgetWelcome };
