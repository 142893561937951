/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget8: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight))
    if (chart1) {
      chart1.render()
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>


      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>

        <div className="row g-10">
          <div className="col-md-3">
            <div className='mt-5 px-3'>
              <div className='d-flex flex-stack mb-10'>
                <div className='me-2'>
                  <h3 className="card-title align-items-start flex-column mb-3">
                    <span className="card-label fw-bold fs-6 text-gray-400 mb-3">CURRENT EARNINGS</span>
                  </h3>
                  <div>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      <img
                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                        alt=''
                        className='h-20px mb-4'
                      />
                      <span className='fw-bolder fs-1'>  ₱48.2k</span>
                    </a>
                    <div className='fs-7 text-muted fw-bold'>As of September 20,2022</div>
                  </div>
                </div>

              </div>

              <div className='d-flex flex-stack mb-10'>
                <div className='me-2'>
                  <h3 className="card-title align-items-start flex-column mb-3">
                    <span className="card-label fw-bold fs-6 text-gray-400 mb-3">LAST WEEK EARNINGS</span>
                  </h3>
                  <div>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      <img
                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                        alt=''
                        className='h-20px mb-4'
                      />       <span className='fw-bolder fs-1'>  ₱48.2k</span>
                    </a>
                    <div className='fs-7 text-muted fw-bold'>Pending until December 20,2022</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div className="col-md-9">
            {/* begin::Chart */}
            <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
            {/* end::Chart */}
          </div>



        </div>









      </div>
      {/* end::Body */}
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 30, 60, 25, 25, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export { MixedWidget8 }
