/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useQuery } from "@tanstack/react-query";
import { ref, orderByChild, equalTo, onValue, query, get } from "firebase/database";
import { database } from "../../../../firebase";
import axios from "axios";
const DEFAULT_IMAGE = require('./asset/empty_icon.png');

type Props = {
  className: string;
};

const customStyles = {
  headCells: {
    style: {
      flex: 1
    },
  },
};

const TableResellers: React.FC<Props> = ({ className }) => {

  const [data, setData] = useState([]);

  let authId = localStorage.getItem("AUTH_ID");

 
  useEffect(() => {
    const resellersQuery = query(
      ref(database, `business_resellers/${authId}`))

      onValue(resellersQuery, (snapshot) => {
        const tmpResellers: any = [];
        const finalResellers = [];


        snapshot.forEach(snap => {
          tmpResellers.push({id: snap.key, name: snap.val()})
        })

        Promise.all(tmpResellers.map(async (reseller) => {
          try {
            const response = await axios.post(`${process.env.REACT_APP_USAP_API_URL}/business-owner/reseller-total-sales`, {
              "reseller_uid": reseller.id,
              "business_uid": authId
            })

            finalResellers.push({...reseller, ...response.data})

          } catch (err){
            return Promise.reject(err);
          }
        })).then(res => {

          setData(finalResellers);
        })


        // setIsLoading(false);
      });
  }, [authId]);

  console.log("Data: ", data);
  const columns = [
    {
      name: "Reseller",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
       <div style={{flexDirection: "row", alignItems: "center"}} >
       <img src={row.reseller_details.avatar_url || DEFAULT_IMAGE} alt='' style={{borderRadius: 30 / 2, height: 30, width: 30, marginRight: 8}} />
       <span>{row.name}</span>
         {/* <a
          href="#!"
          // onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.name}
        </a> */}
       </div>
      ),
    },
    {
      name: "Sales Total",
      selector: (row) => row.total_sales,
      sortable: true,
      cell: (row) => (
        <div>
        <a
          href="#!"
          // onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3 "
        >
          ₱{Number(row.total_sales).toLocaleString(undefined, {minimumFractionDigits: 2})}
        </a></div>
      ),
    },
    {
      name: "Commission Total",
      selector: (row) => row.total_commission,
      sortable: true,
      cell: (row) => (

        <div>
          
        <a
          href="#!"
          // onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          ₱{Number(row.total_commission).toLocaleString(undefined, {minimumFractionDigits: 2})}
        </a>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row?.reseller_details?.kyc_status === "verified" ? "Verified" : "Unverified",
      sortable: true,
      cell: (row) => (
        <div style={{display:"flex", flexDirection: "row", alignItems: 'center'}}>

<div style={{width: 10, height: 10, borderRadius: 10 / 2, marginRight: 4 ,backgroundColor: row?.reseller_details?.kyc_status === "verified" ? "#0076FF" : "#f4b509"}}/>
      <div>
        
        {row?.reseller_details?.kyc_status === "verified" ? "Verified" : "Unverified"}
       
      </div>
        </div>
      ),
    },
   
  ];

  return (
    <div className={`card ${className}`}>
    <DataTableExtensions
      columns={columns}
      data={data}
      print={false}
      export={false}
      filterPlaceholder="Search"
      filter={true}
    >
      <DataTable
        noHeader
        columns={columns}
        data={data}
        customStyles={customStyles}
        highlightOnHover
        pagination
      />
    </DataTableExtensions>
    {/* <Invoice onClose={handleClose} id={selectedId} open={open} /> */}

</div>
  );
};

export { TableResellers };
