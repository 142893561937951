/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import QrReader from "react-qr-scanner";

import { TablesSalesInvoice } from "./TableList";
import { KTSVG } from "../../../_metronic/helpers";

const ScanWrapper: FC = () => {
  const [data, setData] = useState({ text: "none" });
  const [scanMode, setScanMode] = useState(false);

  const triggerScan = () => {
    setScanMode(!scanMode);
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>Scan</PageTitle>
      <div className="text-center">
        {!scanMode ? (
          <>
            <TablesSalesInvoice
              className="card-xl-stretch mb-xl-8"
              resellerUID={data?.text}
              triggerScan={triggerScan}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1>Scan Reseller QR Code</h1>
            <div className=" border-0 pt-5 align-self-start">
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Back to table list"
                onClick={() => triggerScan()}
              >
                <a
                  href="#"
                  className="btn btn-outline btn-color-primary btn-outline-solid btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen058.svg"
                    className="svg-icon-3 text-gray-600"
                  />
                  Back
                </a>
              </div>
            </div>
            <QrReader
              delay={100}
              style={{ height: 440, width: 520 }}
              onError={(err) => {
                console.error(err);
              }}
              onScan={(data) => {
                if (data) {
                  setData(data);
                  setScanMode(false);
                }
              }}
            />
            <br />
            <div
              className="spinner-border text-primary text-center"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { ScanWrapper };
