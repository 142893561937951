/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export function LoginBusiness() {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/USAPBIZ/Images/login-group.png"
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
            className="h-80px"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px  rounded  p-10 p-lg-15 mx-auto">
          <form className="form w-100" noValidate id="kt_login_signin_form">
            {/* begin::Heading */}
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Set Username and Password</h1>
              <div className="text-gray-400 fw-bold fs-7">
                Set your new password to access your USAP Account
              </div>
            </div>

            {/* begin::Form group */}
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mt-3">
                <span className=""> Username </span>
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                value=""
                placeholder="Username"
              ></input>
              <div className=""></div>
            </div>
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label text-dark fs-6 mb-0">
                    Password
                  </label>
                </div>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
              />
            </div>
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label text-dark fs-6 mb-0">
                    Confirm Password
                  </label>
                </div>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
              />
            </div>

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  {!loading && <span className="indicator-label">Set</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>

              {/* end::Link */}
            </div>
            {/* end::Form group */}
          </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
}
