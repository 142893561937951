/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
};

const TablesMembers: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <form
          data-kt-search-element="form"
          className=" mb-3 mt-4  align-items-start flex-column position-relative"
          autoComplete="off"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
          />
          <input
            type="text"
            className="form-control form-control-solid ps-10"
            name="search"
            placeholder="Search..."
            data-kt-search-element="input"
          />
        </form>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user"
        >
          <a
            href="#"
            className="btn btn-outline btn-outline-solid btn-outline-default btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen031.svg"
              className="svg-icon-3 text-gray-600"
            />
            Filter
          </a>
          <a
            href="#"
            className="btn btn-primary px-6 align-self-center text-nowrap"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_customer"
          >
            Add Customer
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive mb-5">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-150px">Customer</th>
                <th className="min-w-140px">Email Address</th>
                <th className="min-w-120px">Tagged As</th>
                <th className="min-w-120px">Status</th>
                <th className="min-w-100px text-end">Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6"> Verified </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl(
                          "/media/svg/avatars/020-girl-11.svg"
                        )}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6"> Verified </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6"> Verified </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/stock/600x400/img-9.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6">
                      {" "}
                      Not Verified{" "}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6"> Verified </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/stock/600x400/img-9.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        Kimberly Bado
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">
                    kimberly.bado@gmail.com
                  </span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">Reseller</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
                    <span className="text-dark d-block fs-6">
                      Not Verified{" "}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#"
                      className="btn btn-sm btn-light me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_edit_customer"
                    >
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/art/art005.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Edit
                    </a>
                    <a href="#" className="btn btn-sm btn-light">
                      <span className="svg-icon svg-icon-3">
                        <KTSVG
                          path="/media/icons/duotune/general/gen027.svg"
                          className="svg-icon-3"
                        />
                      </span>{" "}
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TablesMembers };
