/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useLayout } from "../../../../_metronic//layout/core";
import { AuthHeader } from "./header/AuthHeader";

export function ProductPayment() {
  const [loading, setLoading] = useState(false);
  const { config, classes, attributes } = useLayout();
  const { header } = config;

  const creditCard = () => {
    const el_creditCard = document.getElementById("form-creditCard");
    const el_eWallet = document.getElementById("form-eWallet");
    el_creditCard.classList.remove("hide");
    el_eWallet.classList.add("hide");
  };

  const eWallet = () => {
    const el_creditCard = document.getElementById("form-creditCard");
    const el_eWallet = document.getElementById("form-eWallet");
    el_creditCard.classList.add("hide");
    el_eWallet.classList.remove("hide");
  };

  return (
    <div>
      <AuthHeader />
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed product-container">
        {/* begin::Content */}
        <div className="d-flex flex-center pb-lg-20 ps-4 py-5">
          {/* begin::Wrapper */}
          <div className="w-lg-1000px bg-white rounded  p-10 p-lg-15 mx-auto">
            <div className="d-flex flex-column flex-column-fluid bgi-pos ition-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
              {/* begin::Content */}
              {/* <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'> */}

              <div className="card d-none">
                <div className="row">
                  <div className="col-2">
                    <div className="mb-3 mb-md-0 fw-bold">
                      <span>
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
                          className="h-100px"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-7"></div>
                  <div className="col-3 m-auto">
                    <div>
                      <a
                        href="#"
                        className="btn btn-outline btn-outline-solid btn-outline-primary btn-active-light-primary px-6 align-self-center text-nowrap me-3 mb-1"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_register">
                        Register
                      </a>
                      <a
                        href="#"
                        className="btn btn-primary px-6 align-self-center text-nowrap  mb-1"
                        data-bs-toggle="modal"
                        data-bs-target="#">
                        Login
                      </a>
                    </div>
                  </div>
                  <div className="notice d-flex mb-9 p-6">
                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap"></div>
                  </div>
                </div>
              </div>

              {/* begin::Wrapper */}
              <div className="row g-10">
                <div className="col-md-12 m-auto">
                  <h1 className="mb-3">Customer Details</h1>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-300px px-7 py-5 mb-5">
                        <div className="card-body pt-0 ps-1 text-dark">
                          <div className="text-dark fw-bolder fs-6">
                            Kimberly Bado
                          </div>
                          Unit Hastings Road, Melbourne,
                          <br></br>Victoria, Australia
                          <br></br>+6300872625576
                        </div>

                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-active-light-primary me-3">
                          Delete
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm  btn-light btn-active-light-primary me-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_customer_dets">
                          Edit
                        </a>
                      </div>

                      <h1 className="mb-3">Send Payment</h1>

                      <div
                        className="card card-flush pt-3 mb-5 mb-lg-10 "
                        data-kt-subscriptions-form="pricing">
                        <div className="card-body p-0">
                          <div id="kt_create_new_payment_method">
                            {/* 1 */}
                            <div
                              className="p-5"
                              style={{ backgroundColor: `#FBFBFB` }}>
                              <div className="py-3 ">
                                <div className="d-flex my-3 ms-9">
                                  <label className="form-check form-check-custom form-check-solid me-5">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="payment_method"
                                      checked
                                      onClick={creditCard}></input>
                                  </label>
                                  <div className="d-flex align-items-center fw-bold text-dark">
                                    Credit Card
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-dark">
                                  <div className=" mx-10 ">
                                    Use Visa and Master Card
                                  </div>
                                </div>
                                <div
                                  className="ms-10 mt-2  "
                                  id="form-creditCard">
                                  <div className="row g-6 g-xl-9 ">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 fw-bold mb-2 text-dark">
                                          <span>Card Number</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="0000 0000 0000"
                                          name=""
                                          required
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-6 g-xl-9 ">
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column  fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                          <span>Name on Card</span>
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Full Name"
                                          name=""
                                          required
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                          <span>Expiry Date</span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="MM/YY"
                                          name=""
                                          required
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                          <span>CVV Code</span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0000"
                                          name=""
                                          required
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <br></br>

                            <div
                              className="p-5 hide"
                              style={{ backgroundColor: `#FBFBFB` }}>
                              <div className="py-3">
                                <div className="d-flex my-3 ms-9">
                                  <label className="form-check form-check-custom form-check-solid me-5">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="payment_method"></input>
                                  </label>
                                  <div className="d-flex align-items-center fw-bold text-dark">
                                    Online Banking
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-dark">
                                  <div className=" mx-10 ">
                                    Use your online bank for seamless payment
                                  </div>
                                </div>
                                <div className="ms-10 mt-2">
                                  <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold form-label mt-3">
                                      <span className=""> Choose Bank </span>
                                    </label>
                                    <select
                                      className="form-select text-gray-700"
                                      required>
                                      <option>Select Bank</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                    {/* <input type="text" className="form-control" name="" value="" placeholder='Select ID Category'></input> */}
                                    <div className=""></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="p-5"
                              style={{ backgroundColor: `#FBFBFB` }}>
                              <div className="py-3">
                                <div className="d-flex my-3 ms-9">
                                  <label className="form-check form-check-custom form-check-solid me-5">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="payment_method"
                                      onClick={eWallet}></input>
                                  </label>
                                  <div className="d-flex align-items-center fw-bold text-dark">
                                    E Wallet
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-dark">
                                  <div className=" mx-10 ">
                                    Gcash, Paymaya and GrabWallet
                                  </div>
                                </div>
                                <div
                                  className="ms-10 mt-2  hide"
                                  id="form-eWallet">
                                  <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold form-label mt-3">
                                      <span className="">Choose E-walle</span>
                                    </label>
                                    <select
                                      className="form-select text-gray-700"
                                      required>
                                      <option>Select Wallet</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                    {/* <input type="text" className="form-control" name="" value="" placeholder='Select ID Category'></input> */}
                                    <div className=""></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h1 className="mb-3">Order Summary</h1>
                      <div
                        className="p-10"
                        style={{ backgroundColor: `#FBFBFB` }}>
                        <div className="d-flex flex-wrap">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="d-flex m-auto mt-9 me-9 mb-5">
                                <img
                                  className="symbol m-auto "
                                  src={
                                    "https://cdn.iceberg.com.filoblu.com/rx/768x,ofmt_webp/media//catalog/product/2/2/22EI2P0E05163054255_100.jpg"
                                  }
                                  alt=""
                                  height="80px"
                                  width="80px"
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="d-flex flex-column justify-content-center flex-row-fluid ">
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                  <div className="fw-semibold fs-7 text-gray-600 text-dark mt-3">
                                    Fashion
                                  </div>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center mb-3">
                                  <div className="fs-7 text-dark fw-bold text-hover-primary text-dark lh-base">
                                    Zara White/Blue Floral Puffed up sleeve
                                    Dress
                                  </div>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                  <span className="text-primary pe-3 fs-7 text-center">
                                    300.00
                                  </span>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                  <div className="fw-semibold fs-7 text-gray-600 text-dark mt-3">
                                    Quantity : 2
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                            <tbody className="fw-semibold text-gray-600">
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        Item Price
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-dark">
                                    ₱150.00
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        Service Fee
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-dark">
                                    ₱250.00
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        TOTAL
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-primary ">
                                    ₱1,250.00
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <a
                      href="/product-receipt"
                      className="m-auto btn btn-primary me-2 mb-2 mt-10 px-10">
                      Proceed
                    </a>
                  </div>
                </div>
              </div>
              {/* end::Wrapper */}
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
}
