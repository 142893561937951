/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
// import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { AuthHeader } from "./header/AuthHeader";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { database } from "../../../../firebase";
import cities from "../../../../app/utils/cities.json";
import barangays from "../../../../app/utils/barangays.json";
import { child, get, ref } from "firebase/database";
import MaskedInput from "react-text-mask";
import { useParams } from "react-router-dom";
import Xendit from "xendit-js-node/src/xendit";
import { Modal } from "react-bootstrap-v5";
import Button from "react-bootstrap-v5/lib/esm/Button";
import axios from "axios";

const expiryDateMask = [/[0-9]/, /\d/, "/", /[0-9]/, /\d/];
const CvvMask = [/[0-9]/, /\d/, /\d/];
const CCMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
];
const createNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const zipMask = [/\d/, /\d/, /\d/, /\d/];

interface GuestCheckout {
  firstName: string;
  lastName: string;
  address: string;
  cityMunicipality: string;
  barangay: string;
  emailAddress: string;
  number: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvv: string;
  cardName: string;
  payment_method: string;
  walletType: string;
  zip: string;
}

const inits: GuestCheckout = {
  firstName: "",
  lastName: "",
  address: "",
  cityMunicipality: "",
  barangay: "",
  emailAddress: "",
  number: "",
  cardNumber: "",
  cardExpiry: "",
  cardCvv: "",
  cardName: "",
  payment_method: "",
  walletType: "PH_GCASH",
  zip: "",
};

const createAppSchema = [
  Yup.object().shape({
    firstName: Yup.string().required().label("This"),
    lastName: Yup.string().required().label("This"),
    address: Yup.string().required().label("This"),
    cityMunicipality: Yup.string().required().label("This"),
    barangay: Yup.string().required().label("This"),
    emailAddress: Yup.string().email().required().label("This"),
    number: Yup.string().required().length(11).label("This"),
    // cardNumber: Yup.string().required().label("This"),
    // cardExpiry: Yup.string().required().label("This"),
    // cardCvv: Yup.string().required().label("This"),
    // cardName: Yup.string().required().label("This"),
    walletType: Yup.string().required("This is a required field"),
  }),
  Yup.object().shape({
    firstName: Yup.string().required().label("This"),
    lastName: Yup.string().required().label("This"),
    address: Yup.string().required().label("This"),
    cityMunicipality: Yup.string().required().label("This"),
    barangay: Yup.string().required().label("This"),
    emailAddress: Yup.string().email().required().label("This"),
    number: Yup.string().required().label("This"),
    walletType: Yup.string().required("This is a required field"),
  }),
];

export function GuestCheckout() {
  const CurrencyFormat = require("react-currency-format");
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [initValues] = useState<GuestCheckout>(inits);
  // const [show, setShow] = useState(false);
  const [cardTokenId, setCardTokenId] = useState("");
  const [cardAuthId, setCardAuthId] = useState("");
  const [xendit3dsEndpoint, setXendit3dsEndpoint] = useState("");

  const [cityId, setCityId] = useState('');
  
  const { product, reseller, quantity } =
    useParams<{ product: string; reseller: string; quantity: string }>();
  const [productDetails, setProductDetails] = useState<any>([]);
  const [productImage, setProductImage] = useState(null);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const formRef: any = useRef();
  const productRef: any = useRef();
  const setProductRef: any = (data) => {
    productRef.current = data;
  };
  const tokenRef: any = useRef();
  const setTokenRef: any = (data) => {
    tokenRef.current = data;
  };

  const chargeWalletRef: any = useRef();
  const setChargeWalletRef: any = (data) => {
    chargeWalletRef.current = data;
  };

  //joana
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [showError, setShowError] = useState(false);
  const selectPaymentMethod = (type) => {
    setCurrentSchema(createAppSchema[type == "card" ? 0 : 1]);
    setPaymentMethod(type);
  };

  // get the rtdb reference
  const databaseRef = ref(database);

  const handleClose = () => {
    // setShow(false);
    const threeDsButton: any = document.querySelector("#kt_modal_3ds_submit");
    threeDsButton.removeAttribute("data-kt-indicator");
  };

  //close error message modal
  const handleCloseError = () => {
    setShowError(false);
    const threeDsButton: any = document.querySelector("#kt_modal_3ds_submit");
    threeDsButton.removeAttribute("data-kt-indicator");
  };

  const submitStep = async (values: GuestCheckout, actions: FormikValues) => {
    const threeDsButton: any = document.querySelector("#kt_modal_3ds_submit");

    values.payment_method = paymentMethod;
    if (!values?.payment_method) {
      alert("Please select payment method");
      threeDsButton.removeAttribute("data-kt-indicator");
      return;
    }

    if (values?.payment_method === "card") {
      Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_API_KEY);
      const expData = values?.cardExpiry.split("/");
      const cc_number = values?.cardNumber.replace(/\s+/g, "");
      const tokenData = {
        card_number: cc_number,
        card_exp_month: expData[0],
        card_exp_year: `20${expData[1]}`,
        card_cvn: values?.cardCvv.toString(),
        is_multiple_use: false,
        should_authenticate: true,
        amount: Number(
          parseFloat(productRef?.current?.price) * parseInt(quantity)
        ).toFixed(2),
        currency: "PHP",
      };

      Xendit.card.createToken(tokenData, tokenResponseHandler);
    } else if (values?.payment_method == "wallet") {
      if (!values.walletType) {
        alert("Please select E-wallet");
        threeDsButton.removeAttribute("data-kt-indicator");
        return;
      }

      let reqData = {
        wallet_code: values?.walletType,
        product_uid: product,
        reseller_user_uid: reseller,
        charge_amount: (
          parseFloat(productRef?.current?.price) * parseInt(quantity)
        ).toFixed(2),
        quantity: quantity,
        first_name: formRef?.current?.values?.firstName,
        last_name: formRef?.current?.values?.lastName,
        buyer_shipping_address:
          formRef?.current?.values?.address +
          ", " +
          formRef?.current?.values?.cityMunicipality + ".  " +formRef?.current?.values?.barangay,
        buyer_mobile_number: formRef?.current?.values?.number,
        buyer_email: formRef?.current?.values?.emailAddress,
        city: formRef?.current?.values?.cityMunicipality,
        address: formRef?.current?.values?.address,
        buyer_shipping_zip: formRef?.current?.values?.zip,
      };

      axios
        .post(process.env.REACT_APP_API_URL + "/charge-wallet", reqData)
        .then((resp: any) => {
          setChargeWalletRef(resp.data);
          if (resp?.data?.request_data?.channel_code == "PH_SHOPEEPAY") {
            setXendit3dsEndpoint(
              resp?.data?.xendit_response?.actions?.mobile_deeplink_checkout_url
            );
          } else {
            setXendit3dsEndpoint(
              resp?.data?.xendit_response?.actions?.desktop_web_checkout_url
            );
          }
          // setShow(true);
        })
        .catch((reason: any) => {
          // setShow(false);
          alert("Unable to process payment at this moment.");
          const threeDsButton: any = document.querySelector(
            "#kt_modal_3ds_submit"
          );
          threeDsButton.removeAttribute("data-kt-indicator");
        });
    }
  };

  const tokenResponseHandler = (err: any, token: any, is3ds: any) => {
    if (err) {
      alert(err?.message);
      const threeDsButton: any = document.querySelector("#kt_modal_3ds_submit");
      threeDsButton.removeAttribute("data-kt-indicator");
    }
    if (token) {
      // set the token id
      setCardTokenId(token?.id);

      // set the auth id
      setCardAuthId(token?.authentication_id);

      // use ref for listener
      setTokenRef({
        token_id: token?.id,
        auth_id: token?.authentication_id,
      });
      setXendit3dsEndpoint(token?.payer_authentication_url);
      // setShow(true);
    }
  };

  // handle response from the 3ds response
  const onComplete3ds = (event: any) => {
    try {
      let message = JSON.parse(event?.data);
      // if verified then we charge the card
      if (message?.status === "VERIFIED") {
        //return;

        //
        let reqData = {
          product_uid: product,
          reseller_user_uid: reseller,
          token_id: message?.id,
          authentication_id: message?.authentication_id,
          charge_amount: Number(
            parseFloat(productRef?.current?.price) * parseInt(quantity)
          ).toFixed(2),
          quantity: quantity,
          first_name: formRef?.current?.values?.firstName,
          last_name: formRef?.current?.values?.lastName,
          buyer_shipping_address:
            formRef?.current?.values?.address +
            ", " +
            formRef?.current?.values?.cityMunicipality,
          buyer_mobile_number: formRef?.current?.values?.number,
          buyer_email: formRef?.current?.values?.emailAddress,
          city: formRef?.current?.values?.cityMunicipality,
          address: formRef?.current?.values?.address,
          buyer_shipping_zip: formRef?.current?.values?.zip,
        };
        axios
          .post(process.env.REACT_APP_API_URL + "/charge-card", reqData)
          .then((resp: any) => {
            // setShow(false);
            window.location.href =
              "/product-receipt/" + resp?.data?.reference_id;
          })
          .catch((reason: any) => {
            // setShow(false);
            alert("Unable to process payment at this moment.");
            const threeDsButton: any = document.querySelector(
              "#kt_modal_3ds_submit"
            );
            threeDsButton.removeAttribute("data-kt-indicator");
            threeDsButton.setAttribute("disabled", false);
          });
      } else if (message?.status === "FAILED") {
        // setShow(false);
        alert("Something went wrong. Please try again later.");
        const threeDsButton: any = document.querySelector(
          "#kt_modal_3ds_submit"
        );
        threeDsButton.removeAttribute("data-kt-indicator");
        threeDsButton.removeAttribute("disabled");
      } else if (message?.status === "WALLET_SUCCESS") {
        window.location.href =
          "/product-receipt/" +
          chargeWalletRef?.current?.request_data?.reference_id;
      }
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    const threeDsButton: any = document.querySelector("#kt_modal_3ds_submit");
    threeDsButton.addEventListener("click", function () {
      // Activate indicator
      threeDsButton.setAttribute("data-kt-indicator", "on");
      //threeDsButton.setAttribute("disabled", true);
    });

    window.addEventListener("message", onComplete3ds);

    get(child(databaseRef, "products/active/" + product)).then(
      (snapshot): any => {
        // TODO: redirect to product not found page
        if (!snapshot.exists()) {
          window.location.href = "/product-not-available";
        }

        get(
          child(
            databaseRef,
            "business_resellers/" + snapshot.val()?.owner + "/" + reseller
          )
        ).then((businessResellerSnap): any => {
          if (!businessResellerSnap.exists()) {
            // user is not a business member
            window.location.href = "/product-not-available";
            return;
          }
        });

        // set the product details
        setProductDetails(snapshot.val());
        setProductRef(snapshot.val());

        let totalPrice = (snapshot.val().price * parseInt(quantity)).toFixed(2);
        setTotalPrice(totalPrice);
        // we get the first entry in image object
        setProductImage(Object.values(snapshot.val().images)[0]);
      }
    );
  }, [product, reseller, quantity]);


  return xendit3dsEndpoint ? (
    <iframe
      src={xendit3dsEndpoint}
      style={{ height: "100%", width: "100%" }}
    ></iframe>
  ) : (
    <div>
      {/* <Modal show={show} onHide={() => {}} fullscreen={true}>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={showError} onHide={() => {}}>
        <Modal.Body>
          <div className="center px-5" data-kt-subscriptions-form="pricing">
            <div className="card-body p-0">
              <div className="text-center mb-10 mt-10">
                <h1 className="text-danger fs-1 mb-2 pt-5">Ooops!</h1>
                <div className="text-gray-400 fw-bold fs-7">
                  Some fields are required. Please complete the form to submit!
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="fv-row mb-10">
          <div className="text-center">
            <button
              type="button"
              data-bs-dismiss="modal"
              className="btn btn-sm btn-primary w-50 mb-5"
              onClick={handleCloseError}
            >
              Okay
            </button>
          </div>
        </div>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseError}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <AuthHeader />

      <div className="d-flex flex-column flex-column-fluid bgi-pos ition-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed  product-container">
        {/* begin::Wrapper */}
        <div className="w-lg-1000px bg-white rounded pt-0 p-10 align-self-center">
          <div className="row g-10 mt-3">
            <div className="col-md-12 m-auto">
              <h1 className="mb-3">Details</h1>

              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                innerRef={formRef}
                onSubmit={submitStep}
                validateOnMount
              >
                {(props) => {
                  return  <Form className="form" id="kt_modal_new_target_form">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row g-6 g-xl-9 ">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">First Name </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control form-control-lg"
                              name="firstName"
                              placeholder="First Name"
                              enterkeyhint="next"
                              required
                              autofocus
                            />
                            <div className="text-danger">
                              <ErrorMessage name="firstName" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required"> Last Name </span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              enterkeyhint="next"
                              required
                              autofocus
                            />
                            <div className="text-danger">
                              <ErrorMessage name="lastName" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">Address</span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              enterkeyhint="next"
                              autofocus
                              required
                            />
                            <div className="text-danger">
                              <ErrorMessage name="address" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">
                                City / Municipality
                              </span>
                            </label>
                            {/* <Field
                              type="text"
                              className="form-control"
                              placeholder="City / Municipality"
                              name="cityMunicipality"
                            /> */}
                            <Field
                              as="select"
                              name="cityMunicipality"
                              className="form-control"
                              enterkeyhint="next"
                              autofocus
                              required
                              onChange={(e) => {
                                const cityIndex = cities.findIndex(c => c.name === e.currentTarget.value);

                                if(cityIndex > -1) {
                                    setCityId(cities[cityIndex].id)
                                } else {
                                    setCityId('')
                                }

                                props.setFieldValue('cityMunicipality', e.currentTarget.value )
                              }}
                            >
                              <option value="" selected>
                                Select city
                              </option>
                             {cities.map((option, index) => <option value={option.name} key={index} >
                                              {option.name}
                                          </option>)}
                            </Field>
                            <div className="text-danger">
                              <ErrorMessage name="cityMunicipality" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">
                                Barangay
                              </span>
                            </label>
                            {/* <Field
                              type="text"
                              className="form-control"
                              placeholder="City / Municipality"
                              name="cityMunicipality"
                            /> */}
                            <Field
                              as="select"
                              name="barangay"
                              className="form-control"
                              enterkeyhint="next"
                              autofocus
                              required
                            >
                              <option value="" selected>
                                Select barangay
                              </option>
                             {barangays.filter(barangay => barangay.city_code === cityId).map((option, index) => <option key={index} >
                                              {option.name}
                                          </option>)}
                            </Field>
                            <div className="text-danger">
                              <ErrorMessage name="cityMunicipality" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column  fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">Zip Code</span>
                            </label>
                            <Field
                              name="zip"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={zipMask}
                                  placeholder=""
                                  className="form-control"
                                  type="tel"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  enterkeyhint="next"
                                  autofocus
                                  required
                                />
                              )}
                              enterkeyhint="next"
                              autofocus
                              required
                            />
                            <div className="text-danger">
                              <ErrorMessage name="zip" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column  fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">Email Address</span>
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              name="emailAddress"
                              enterkeyhint="next"
                              autofocus
                              required
                            />
                            <div className="text-danger">
                              <ErrorMessage name="emailAddress" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                              <span className="required">Mobile Number</span>
                            </label>
                            <Field
                              name="number"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={createNumberMask}
                                  placeholder=""
                                  className="form-control"
                                  type="tel"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  enterkeyhint="next"
                                  autofocus
                                  required
                                />
                              )}
                              enterkeyhint="next"
                              autofocus
                              required
                            />
                            <div className="text-danger">
                              <ErrorMessage name="number" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <h1 className="mb-3  mt-5">Send Payment</h1>

                      <div
                        className="card card-flush pt-3 mb-5 mb-lg-10 "
                        data-kt-subscriptions-form="pricing"
                      >
                        <div className="card-body p-0">
                          <div id="kt_create_new_payment_method">
                         

                            <br></br>

                            <div
                              className="p-5 hide"
                              style={{ backgroundColor: `#FBFBFB` }}
                            >
                              <div className="py-3">
                                <div className="d-flex my-3 ms-9">
                                  <label className="form-check form-check-custom form-check-solid me-5">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="payment_method"
                                      value="bank"
                                    />
                                  </label>
                                  <div className="d-flex align-items-center fw-bold text-dark">
                                    Online Banking
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-dark">
                                  <div className=" mx-10 ">
                                    Use your online bank for seamless payment
                                  </div>
                                </div>
                                <div className="ms-10 mt-2">
                                  <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold form-label mt-3">
                                      <span className=""> Choose Bank </span>
                                    </label>
                                    <select
                                      className="form-select text-gray-700"
                                      required
                                    >
                                      <option>Select Bank</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                    {/* <input type="text" className="form-control" name="" value="" placeholder='Select ID Category'></input> */}
                                    <div className=""></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="p-5"
                              style={{ backgroundColor: `#FBFBFB` }}
                            >
                              <div className="py-3">
                                <div className="d-flex my-3 ms-9">
                                  <label className="form-check form-check-custom form-check-solid me-5">
                                    <Field
                                      className="form-check-input form-control"
                                      type="radio"
                                      name="payment_method"
                                      value="wallet"
                                      onClick={() => {
                                        selectPaymentMethod("wallet");
                                      }}
                                      checked={paymentMethod === "wallet"}
                                    ></Field>
                                  </label>
                                  <div className="d-flex align-items-center fw-bold text-dark">
                                    E Wallet
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-dark">
                                  <div className=" mx-10 ">
                                    Gcash, Maya and GrabPay
                                  </div>
                                </div>
                                {paymentMethod === "wallet" && (
                                  <div
                                    className="ms-10 mt-2"
                                    id="form-eWallet"
                                  >
                                    <div className="fv-row mb-7">
                                      <label className="fs-6 fw-semibold form-label mt-3">
                                        <span className="">
                                          Choose E-wallet
                                        </span>
                                      </label>
                                      <Field
                                        as="select"
                                        className="form-select select text-gray-700"
                                        name="walletType"
                                      >
                                        <option value="PH_GCASH">
                                          GCASH
                                        </option>
                                        <option value="PH_PAYMAYA">
                                          Maya
                                        </option>
                                        <option value="PH_GRABPAY">
                                          GrabPay
                                        </option>
                                        {/* <option value="PH_SHOPEEPAY">
                                          ShopeePay
                                        </option> */}
                                      </Field>
                                      <div className="text-danger">
                                        <ErrorMessage name="walletType" />
                                      </div>

                                      <div className=""></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h1 className="mb-3">Order Summary</h1>
                      <div className=" bg-primary bg-opacity-5 p-5">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <div className="d-flex justify-content-between mt-3 mb-5">
                              <img
                                className="symbol--"
                                src={productImage}
                                alt={productDetails.name}
                                width="100px"
                                height="100px"
                              />
                              <div className="d-flex flex-column justify-content-center flex-row-fluid ps-2">
                                <p className="d-flex fs-12 fw-bolder align-items-center mb-0">
                                  <p
                                    style={{ maxWidth: 150 }}
                                    className="fs-12 text-dark fw-bolder text-hover-primary text-dark mb-2"
                                  >
                                    {productDetails?.name}
                                  </p>
                                </p>

                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                                  <tbody className="fw-semibold text-gray-600">
                                    <tr>
                                      <td className="py-0">
                                        <div className="d-flex fs-6 fw-semibold align-items-center">
                                          <span className="text-primary pe-3 fs-7 text-center">
                                            <CurrencyFormat
                                              value={productDetails?.price?.toFixed(
                                                2
                                              )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"₱"}
                                            />
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-0">
                                        <div className="d-flex fs-6 fw-semibold align-items-center">
                                          <div className="fw-semibold fs-7 text-gray-600 text-dark">
                                            Qty : {quantity}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      Item Price
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-dark">
                                  <CurrencyFormat
                                    value={productDetails?.price?.toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₱"}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      Service Fee
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-dark">
                                  ₱0.00
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      TOTAL
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-primary ">
                                  <CurrencyFormat
                                    value={totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₱"}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <div
                        className="p-10"
                        style={{ backgroundColor: `#FBFBFB` }}>
                        <div className="">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="d-flex flex-center mt-9 mb-5">
                                <img
                                  className="symbol m-auto"
                                  src={productImage}
                                  alt={productDetails.name}
                                  width="80px"
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="d-flex flex-column justify-content-center flex-row-fluid mb-5 mt-9">
                             
                                <div className="d-flex fs-6 fw-semibold align-items-center mb-3">
                                  <div className="fs-7 text-dark fw-bold text-hover-primary text-dark lh-base">
                                    {productDetails?.name}
                                  </div>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                  <span className="text-dark pe-3 fs-7 text-center">
                                    {productDetails.description}
                                   
                                  </span>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                  <div className="fw-semibold fs-7 text-gray-600 text-dark mt-3">
                                    Quantity : {quantity}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                            <tbody className="fw-semibold text-gray-600">
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        Item Price
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-dark">
                                    <CurrencyFormat
                                      value={productDetails?.price?.toFixed(
                                        2
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₱"}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        Service Fee
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-dark">
                                    ₱0.00
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="py-0">
                                  <div className="d-flex align-items-center">
                                    <div className="ms-5">
                                      <div className="fw-bold mt-5 text-dark">
                                        TOTAL
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end py-0">
                                  <div className="fw-bold mt-5 text-primary ">
                                    <CurrencyFormat
                                      value={totalPrice}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₱"}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="m-auto text-center mt-5">
                    <button
                      type="submit"
                      id="kt_modal_3ds_submit"
                      className="btn btn-primary"
                      disabled={!props.isValid}
                      // data-bs-toggle={!props.isValid && "modal"}
                      // data-bs-target={
                      //   !props.isValid && "#kt_modal_error_message"
                      // }
                    >
                      <span className="indicator-label">Proceed</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                    {!props.isValid &&
                      props.isSubmitting &&
                      setShowError(true)}
                    {/* <a href="/product-receipt" className="m-auto btn btn-primary me-2 mb-2 mt-10 px-10" >
                Proceed
              </a> */}
                  </div>
                </Form>
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
