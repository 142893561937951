import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Container } from "react-bootstrap-v5";

export function ForgotPassword() {
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/USAPBIZ/Images/login-group.png"
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column  p-10 pb-lg-20">
        <a href="/" className="">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
            className="h-80px"
          />
        </a>
        <div className="w-lg-500px bg-white rounded  p-5 p-lg-15 mx-auto">
          <Container className="bg-gray-200 p-5 text-center">
            <div className="text-gray-600 fw-bold fs-4">
              Please contact{" "}
              <a href="mailto:support@usap.com.ph">support@usap.com.ph</a> to
              reset your password
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
