/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../helpers";
import { Link, useParams } from "react-router-dom";
import { osName, isMobile } from "react-device-detect";

// define the interface
interface Props {
  product: string;
  reseller: string;
  quantity: number;
}

const IOS_APP_URL =
  "https://apps.apple.com/ph/app/u-sell-and-pay-usap/id1668946136";
const ANDROID_APP_URL =
  "https://play.google.com/store/apps/details?id=com.usap.master";
const GuestLogin: FC<Props> = (props) => {
  const { product, reseller } =
    useParams<{ product: string; reseller: string }>();
  const [loading, setLoading] = useState(false);
  return (
    <div className="modal fade" id="kt_modal_guest_login" aria-hidden="true">
      <div
        className="modal-dialog   mw-480px"
        style={{
          position: "relative",
          left: "-1px",
          display: "flex",
          alignItems: "center",
          minHeight: "calc(100% - 0rem)",
        }}
      >
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-xl-120 pt-0 pb-15">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <a href="#" className="mb-0">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
                  className="h-80px"
                />
              </a>

              <div
                className="card card-flush pt-3 mb-5 mb-lg-10 "
                data-kt-subscriptions-form="pricing"
              >
                <div className="card-body pt-0">
                  <form
                    className="form w-100"
                    noValidate
                    id="kt_login_signin_form"
                  >
                    <div className="text-center">
                      <h1 className="text-dark mb-3">Continue to checkout</h1>
                      <br />
                    </div>
                    {/* begin::Form group */}
                    <div className="fv-row mb-10">
                      <div className="text-center">
                        <a
                          href="https://slip.com.ph/"
                          className="btn btn-lg btn-primary w-100 mb-5 btn-open-website d-md-block"
                          target=""
                          rel="noreferrer"
                        >
                          Open Website
                        </a>

                        {osName === "Android" && (
                          <a
                            href={ANDROID_APP_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-primary w-100 mb-5 "
                          >
                            Download App
                          </a>
                        )}

                        {osName === "iOS" && (
                          <a
                            href={IOS_APP_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-lg btn-primary w-100 mb-5 "
                          >
                            Download App
                          </a>
                        )}

                        <div className="text-center border-top mt-5">
                          <span className="text-gray-400 overlap-custom">
                            Or
                          </span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-lg btn-light w-100 mt-5 text-primary"
                          data-bs-dismiss="modal"
                        >
                          <Link
                            to={
                              "/product-preview/" +
                              props.product +
                              "/" +
                              props.reseller +
                              "/" +
                              props.quantity
                            }
                          >
                            Continue as Guest
                          </Link>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GuestLogin };
