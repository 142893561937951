import { useState, useEffect } from "react";
import { database } from "../../../../firebase";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ref, onValue, update } from "firebase/database";
import moment from "moment";
import { Container, Col, Modal, Row } from "react-bootstrap-v5";
import { moneyFormatter } from "../../../utils/formatter";
import QRCode from "react-qr-code";

function Invoice({ open, onClose, id }) {
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [delivered, setDelivered] = useState<Boolean>(false);
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    const salesOrderDetails = ref(database, "sale_transactions/" + id);
    onValue(salesOrderDetails, (snapshot) => {
      let newOrderDetails: any = {};
      const so = snapshot.val();
      let name = "";
      if (so?.user_details === undefined) {
        name = "";
      } else {
        if (so.user_details.display_name === undefined) {
          name = so.user_details.first_name + " " + so.user_details.last_name;
        } else {
          name = so.user_details.display_name;
        }
      }
      const createdAtDate = so?.created_at || new Date();
      let a = moment(createdAtDate);
      newOrderDetails = {
        buyer_name: name,
        invoice_date: a.format("DD MMMM YYYY"),
        ...snapshot.val(),
      };
      setDelivered(so?.delivered);

      // updateStarCount(postElement, data);
      setOrderDetails(newOrderDetails);
    });
  }, [id]);

  const handleMarkAsDelivered = () => {
    const status = !delivered;
    update(ref(database, `sale_transactions/${id}`), {
      delivered: status,
    }).then(() => {
      setDelivered(status);
    });
  };
  let modalID = "kt_modal_invoice_detail_" + id;

  const renderSeeMore = () => {
    if (orderDetails?.product_details?.description?.length > 50) {
      return seeMore ? (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          &emsp;See Less
        </a>
      ) : (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          ... See More
        </a>
      );
    }
    return "";
  };
  return (
    <Modal
      id={modalID}
      role="dialog"
      data-backdrop="static"
      aria-hidden="true"
      tabIndex="-1"
      zindex={999}
      show={open}
      onHide={onClose}
    >
      {Object.keys(orderDetails) && Object.keys(orderDetails).length ? (
        <div className="mw-480px">
          <div className="modal-header">
            <h1>
              Invoice
              <span> #{orderDetails.usap_sale_uid}</span>
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>

          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 64,
              width: "100%",
            }}
          >
            {orderDetails.reseller_uid && (
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={orderDetails.reseller_uid}
                viewBox={`0 0 256 256`}
              />
            )}
          </div>
          <div className="modal-content">
            <Container fluid className="p-10">
              <Row>
                <Col>
                  <div className="d-flex flex-stack">
                    <div className="text-dark fw-bolder text-hover-primary fs-6">
                      {orderDetails.invoice_date}
                    </div>
                    {orderDetails?.delivered ? (
                      <div className="d-flex align-items-center">
                        <span className="bullet bg-success me-2 h-10px w-10px"></span>
                        <span className="fw-bold d-block fs-7 text-dark">
                          Delivered
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span className="bullet bg-primary me-2 h-10px w-10px"></span>
                        <span className="fw-bold d-block fs-7 text-dark">
                          Paid - {orderDetails.charge_type_name}
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-dark fw-bolder fs-6 my-5">
                    Recipient Details
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <div className="symbol symbol-45px me-5">
                    <img
                      className="symbol symbol-circle"
                      src={
                        orderDetails?.user_details?.avatar_url
                          ? orderDetails?.user_details?.avatar_url
                          : toAbsoluteUrl("/media/logos/USAP_I-03.png")
                      }
                      alt=""
                    />
                  </div>
                </Col>
                <Col xs={10}>
                  <div className="text-dark fw-bolder fs-6">
                    {orderDetails.buyer_name}
                    <span className="text-black-50">
                      {orderDetails?.user_details?.username
                        ? ` @${orderDetails?.user_details?.username}`
                        : " Guest Checkout"}
                    </span>
                  </div>
                  <div className="text-black-50">
                    {
                      orderDetails?.purchase_contact_details
                        ?.buyer_shipping_address
                    }{" "}
                    ,{" "}
                    {orderDetails?.purchase_contact_details?.buyer_shipping_zip}
                  </div>

                  <div className="text-black-50">
                    +63
                    {
                      orderDetails?.purchase_contact_details
                        ?.buyer_mobile_number
                    }
                  </div>
                  <div className="text-black-50">
                    {orderDetails?.purchase_contact_details?.buyer_email}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-dark fw-bolder fs-6 my-2">
                    Reseller Details
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <div className="symbol symbol-40px">
                    <img
                      className="symbol"
                      src={toAbsoluteUrl("/media/logos/USAP_I-03.png")}
                      alt=""
                    />
                  </div>
                </Col>

                <Col xs={10}>
                  <div className="text-dark fw-bolder fs-6">
                    {orderDetails?.reseller_details?.name}
                  </div>

                  <div className="vstack gap-2">
                    <div className="w-bold text-gray-600 text-truncate">
                      {orderDetails?.reseller_details?.email}
                    </div>
                  </div>

                  <div className="vstack gap-2">
                    <div className="w-bold text-gray-600 text-truncate">
                      {orderDetails?.reseller_details?.mobile}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-dark fw-bolder fs-6 py-5">
                    Product Details
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <div className="symbol symbol-40px">
                    <img
                      className="symbol"
                      style={{ background: "gray" }}
                      src={
                        orderDetails?.product_details?.images
                          ? orderDetails?.product_details?.images[
                              Object.keys(
                                orderDetails?.product_details?.images
                              )[0]
                            ]
                          : toAbsoluteUrl("/media/logos/USAP_I-03.png")
                      }
                      alt=""
                    />
                  </div>
                </Col>

                <Col xs={7}>
                  <div className="vstack gap-2">
                    <div className="w-bold text-dark">
                      {orderDetails?.product_details?.name}
                    </div>

                    <div className="w-bold text-gray-600">
                      {orderDetails?.product_details?.description?.length >
                        50 && seeMore
                        ? orderDetails?.product_details?.description
                        : `${orderDetails?.product_details?.description?.slice(
                            0,
                            50
                          )} `}
                      {renderSeeMore()}
                    </div>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="vstack gap-2">
                    <div className="fw-bold text-dark">
                      {moneyFormatter(orderDetails?.product_details?.price)}
                    </div>
                    <div className="text-gray-600">
                      x{orderDetails.quantity}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr className="mb-5" style={{ color: "gray" }} />
                </Col>
              </Row>

              <Row 
                 >
                <Col
                  xs={8}
                  className="d-flex align-items-center fw-bold text-dark"
                >
                  Total :
                </Col>
                <Col xs={4} className="text-end fw-bold text-dark">
                  {moneyFormatter(orderDetails.charge_amount)}
                </Col>
              </Row>

              {orderDetails.fulfillment_center_details && <Row  style={{marginTop: 20}}>
                <Col
                  xs={8}
                  className="d-flex align-items-center fw-bold text-dark"
                >
                  {orderDetails.fulfillment_center_details.name}
                </Col>

                <Col
                  xs={8}
                  className="d-flex align-items-center fw-bold text-dark"
                >
                  <div className="text-black-50">
                  {`${orderDetails.fulfillment_center_details.address_1}, ${orderDetails.fulfillment_center_details.address_2 ? `${orderDetails.fulfillment_center_details.address_2},` : ''} ${orderDetails.fulfillment_center_details.barangay_district}, ${orderDetails.fulfillment_center_details.city_municipality}, ${orderDetails.fulfillment_center_details.state_province}, ${orderDetails.fulfillment_center_details.postal_code}`}
                
                  </div>
                 </Col>


                <Col
                  xs={8}
                  className="d-flex align-items-center fw-bold text-dark"
                >
                  <div className="text-black-50">
                  {`${orderDetails.fulfillment_center_details.phone_number}`}
                
                  </div>
                 </Col>
              </Row>
}
              <Row>
                <Col>
                  <label className="form-check form-switch form-check-custom form-check-solid mt-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={Boolean(orderDetails?.delivered)}
                      onChange={() => {
                        if (
                          !orderDetails?.delivered &&
                          window.confirm(
                            "Are you sure you want to mark this SO as delivered? Note: this action is irreversible"
                          )
                        ) {
                          handleMarkAsDelivered();
                        }
                      }}
                      disabled={orderDetails?.delivered}
                    />
                    <span className="form-check-label fw-semibold text-muted">
                      Mark as Delivered
                    </span>
                  </label>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

export { Invoice };
