import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_metronic/partials";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { InventoryWrapper } from "../pages/inventory/InventoryWrapper";
import { PromosAndDealsWrapper } from "../pages/promos-and-deals/PromosAndDeals";
import { SalesInvoiceWrapper } from "../pages/invoice/SalesInvoiceWrapper";
import { ReportsWrapper } from "../pages/reports/ReportsWrapper";
import { AccountReceivablesWrapper } from "../pages/account-receivable/AccountReceivablesWrapper";
import { MembersWrapper } from "../pages/members/MembersWrapper";
import { ProductWrapper } from "../pages/product/ProductWrapper";
import { ResellersWrapper } from "../pages/resellers/ResellersWrapper";
import { PayoutsWrapper } from "../pages/payouts/PayoutsWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { ScanWrapper } from "../pages/scan/ScanWrapper";
import { EmptyState } from "../modules/auth/components/EmptyState";

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(
    () => import("../pages/layout-builder/BuilderPageWrapper")
  );
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/inventory" component={InventoryWrapper} />
        <Route path="/fulfillment-centers" component={PromosAndDealsWrapper} />
        <Route path="/members" component={MembersWrapper} />
        <Route path="/product" component={ProductWrapper} />
        <Route
          path="/account-receivables"
          component={AccountReceivablesWrapper}
        />
        <Route path="/resellers" component={ResellersWrapper} />
        <Route path="/payouts" component={PayoutsWrapper} />
        <Route path="/invoice" component={SalesInvoiceWrapper} />
        <Route path="/reports" component={ReportsWrapper} />
        <Route path="/scan" component={ScanWrapper} />
        <Route path="/builder" component={BuilderPageWrapper} />
        <Route path="/crafted/pages/profile" component={ProfilePage} />
        <Route path="/crafted/pages/wizards" component={WizardsPage} />
        <Route path="/crafted/widgets" component={WidgetsPage} />
        <Route path="/crafted/account" component={AccountPage} />
        <Route path="/apps/chat" component={ChatPage} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Redirect from="/auth" to="/invoice" />
        <Redirect exact from="/" to="/invoice" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
