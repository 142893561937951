/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const AddCustomer: FC = () => {
  // const users = [
  //   {
  //     avatar: '/media/avatars/300-6.jpg',
  //     name: 'Emma Smith',
  //     email: 'e.smith@kpmg.com.au',
  //     access: '1',
  //   },
  //   {
  //     state: 'danger',
  //     name: 'Melody Macy',
  //     email: 'melody@altbox.com',
  //     access: '1',
  //   },
  //   {
  //     avatar: '/media/avatars/300-1.jpg',
  //     name: 'Max Smith',
  //     email: 'max@kt.com',
  //     access: '3',
  //   },
  //   {
  //     avatar: '/media/avatars/300-1.jpg',
  //     name: 'Sean Bean',
  //     email: 'sean@dellito.com',
  //     access: '2',
  //   },
  //   {
  //     avatar: '/media/avatars/300-25.jpg',
  //     name: 'Brian Cox',
  //     email: 'brian@exchange.com',
  //     access: '3',
  //   },
  //   {
  //     state: 'warning',
  //     name: 'Mikaela Collins',
  //     email: 'mikaela@pexcom.com',
  //     access: '2',
  //   },
  //   {
  //     avatar: '/media/avatars/300-9.jpg',
  //     name: 'Francis Mitcham',
  //     email: 'f.mitcham@kpmg.com.au',
  //     access: '3',
  //   },
  //   {
  //     state: 'danger',
  //     name: 'Olivia Wild',
  //     email: 'olivia@corpmail.com',
  //     access: '2',
  //   },
  //   {
  //     state: 'info',
  //     name: 'Neil Owen',
  //     email: 'owen.neil@gmail.com',
  //     access: '1',
  //   },
  //   {
  //     avatar: '/media/avatars/300-23.jpg',
  //     name: 'Dan Wilson',
  //     email: 'dam@consilting.com',
  //     access: '3',
  //   },
  //   {
  //     state: 'danger',
  //     name: 'Emma Bold',
  //     email: 'emma@intenso.com',
  //     access: '2',
  //   },
  //   {
  //     avatar: '/media/avatars/300-12.jpg',
  //     name: 'Ana Crown',
  //     email: 'ana.cf@limtel.com',
  //     access: '1',
  //   },
  //   {
  //     state: 'primary',
  //     name: 'Robert Doe',
  //     email: 'robert@benko.com',
  //     access: '3',
  //   },
  //   {
  //     avatar: '/media/avatars/300-13.jpg',
  //     name: 'John Miller',
  //     email: 'miller@mapple.com',
  //     access: '3',
  //   },
  //   {
  //     state: 'success',
  //     name: 'Lucy Kunic',
  //     email: 'lucy.m@fentech.com',
  //     access: '2',
  //   },
  //   {
  //     avatar: '/media/avatars/300-21.jpg',
  //     name: 'Ethan Wilder',
  //     email: 'ethan@loop.com.au',
  //     access: '1',
  //   },
  //   {
  //     avatar: '/media/avatars/300-12.jpg',
  //     name: 'Ana Crown',
  //     email: 'ana.cf@limtel.com',
  //     access: '3',
  //   },
  // ]

  return (
    <div className='modal fade' id='kt_modal_add_customer' aria-hidden='true'>
      <div className='modal-dialog mw-480px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-left '>
              <h1 className='mb-3'>Add Customer</h1>


            </div>

            <div className='separator d-flex flex-center mb-8'>
            </div>


            <div className='mb-10'>
              <div className="d-flex flex-column mb-5 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                  <span className="required">Customer Name</span>
                </label>
                <input className="form-control" placeholder="Name" name="website"></input>
              </div>

              <div className="d-flex flex-column mb-5 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                  <span className="required">Tagged As</span>
                </label>
                <input className="form-control" placeholder="Select Tagged" name="website"></input>
              </div>

              {/* <div className='mh-300px scroll-y me-n7 pe-7'>
                {users.map((user, i) => {
                  return (
                    <div
                      className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                      key={i}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-35px symbol-circle'>
                          {user.avatar && <img alt='Pic' src={toAbsoluteUrl(user.avatar)} />}
                          {user.state && (
                            <div className='symbol symbol-35px symbol-circle'>
                              <span
                                className={`symbol-label bg-light-${user.state} text-${user.state} fw-bold`}
                              >
                                {user.name.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='ms-5'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {user.name}
                          </a>
                          <div className='fw-bold text-muted'>{user.email}</div>
                        </div>
                      </div>


                    </div>
                  )
                })}
              </div> */}
            </div>

            <div className="text-center">
              <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary">
                <span className="indicator-label">Add</span>
                <span className="indicator-progress">Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>


  )
}

export { AddCustomer }
