/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthHeader } from "./header/AuthHeader";
import { child, get, ref } from "firebase/database";
import { database } from "../../../../firebase";

export function ProductReceipt() {
  const CurrencyFormat = require("react-currency-format");
  const { sale_uid } = useParams<{ sale_uid: string }>();
  const [saleDetails, setSaleDetails] = useState<any>([]);
  const [productImage, setProductImage] = useState(null);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  // get the rtdb reference
  const databaseRef = ref(database);
  useEffect(() => {
    get(child(databaseRef, "xendit_payment_webhooks/" + sale_uid))
      .then((snapshot): any => {
        setSaleDetails(snapshot.val());
        setProductImage(
          Object.values(
            snapshot.val()?.sale_details?.product_details?.images
          )[0]
        );
        let totalPrice = parseFloat(
          snapshot.val()?.sale_details?.charge_amount
        ).toFixed(2);
        setTotalPrice(totalPrice);
      })
      .catch(() => window.location.assign("/"));
  }, [databaseRef, sale_uid]);

  if (
    new Date(saleDetails?.sale_details?.created_at) <
    new Date(new Date().getTime() - 1 * 60000)
  ) {
    window.location.assign("/");
  }
  return (
    <div>
      <AuthHeader />
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed product-container">
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20">
          {/* begin::Wrapper */}
          <div className="container-fluid w-lg-1000px bg-white rounded p-lg-15 mx-auto">
            <div className="container-fluid d-flex flex-column flex-column-fluid bgi-pos ition-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
              {/* begin::Content */}
              {/* begin::Wrapper */}
              <div className="row g-10">
                <div className="col-md-12 m-auto">
                  <div className="row">
                    <div className="col-sm-12 col-lg-7">
                      <div className="card-body pt-0 ps-1 text-dark">
                        <div className="text-dark fw-bolder fs-6">
                          Order No.:
                        </div>
                        <span>{saleDetails?.sale_details?.usap_sale_uid}</span>
                      </div>

                      <h1 className="mb-3">
                        Thank you{" "}
                        <span>
                          {saleDetails?.sale_details?.user_details?.first_name}
                        </span>
                        !
                      </h1>
                      <div className=" bg-primary bg-opacity-5 p-10 ">
                        <div className="row">
                          <h1 className="mb-3">Order Successful!</h1>
                          <div className="fs-7 text-dark fw-bold text-dark lh-base">
                            Thank you for your purchase! We've notified the
                            seller to ship order #
                            <span>
                              {saleDetails?.sale_details?.usap_sale_uid}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className=" bg-primary bg-opacity-5 p-10 ">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <h3>Customer Information</h3>

                            <div className="card-body pt-0 ps-1 text-dark">
                              <div
                                className="text-dark fw-bolder fs-6"
                                style={{ wordWrap: `break-word` }}
                              >
                                {
                                  saleDetails?.sale_details?.user_details
                                    ?.first_name
                                }{" "}
                                {
                                  saleDetails?.sale_details?.user_details
                                    ?.last_name
                                }
                              </div>
                              {
                                saleDetails?.sale_details
                                  ?.purchase_contact_details
                                  ?.buyer_shipping_address
                              }
                              &nbsp;,&nbsp;
                              {
                                saleDetails?.sale_details
                                  ?.purchase_contact_details?.buyer_shipping_zip
                              }
                              <br></br>
                              {
                                saleDetails?.sale_details
                                  ?.purchase_contact_details?.buyer_email
                              }
                              <br></br>
                              {
                                saleDetails?.sale_details
                                  ?.purchase_contact_details
                                  ?.buyer_mobile_number
                              }
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <h3>Resellers Information</h3>

                            <div className="card-body pt-0 ps-1 text-dark">
                              <div
                                className="text-dark fw-bolder fs-6"
                                style={{ wordWrap: `break-word` }}
                              >
                                {
                                  saleDetails?.sale_details?.reseller_details
                                    ?.name
                                }
                              </div>
                              {
                                saleDetails?.sale_details?.reseller_details
                                  ?.mobile
                              }
                              <br></br>
                              {
                                saleDetails?.sale_details?.reseller_details
                                  ?.email
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-5 mt-10 pt-5 mb-10">
                      <h1 className="mb-5">Order Summary</h1>
                      <div className=" bg-primary bg-opacity-5 p-5">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <div className="d-flex justify-content-between mt-3 mb-5">
                              <img
                                className="symbol-- "
                                src={productImage}
                                alt={saleDetails?.product_details?.name}
                                width="100px"
                                height="100px"
                              />
                              <div className="d-flex flex-column justify-content-center flex-row-fluid ps-2">
                                <p className="d-flex fs-12 fw-bolder align-items-center mb-0 ">
                                  <p className="fs-12 text-dark fw-bolder text-hover-primary text-dark mb-2">
                                    {
                                      saleDetails?.sale_details?.product_details
                                        ?.name
                                    }
                                  </p>
                                </p>

                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                                  <tbody className="fw-semibold text-gray-600">
                                    <tr>
                                      <td className="py-0">
                                        <div className="d-flex fs-6 fw-semibold align-items-center">
                                          <span className="text-primary pe-3 fs-7 text-center">
                                            <CurrencyFormat
                                              value={saleDetails?.sale_details?.product_details?.price.toFixed(
                                                2
                                              )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"₱"}
                                            />
                                          </span>
                                        </div>
                                      </td>
                                      <td className="py-0">
                                        <div className="d-flex fs-6 fw-semibold align-items-center">
                                          <div className="fw-semibold fs-7 text-gray-600 text-dark">
                                            Qty :{" "}
                                            {
                                              saleDetails?.sale_details
                                                ?.quantity
                                            }
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          {/* <div className="d-flex fs-6 fw-semibold align-items-center hidden">
                                  <div className="fw-semibold fs-7 text-gray-600 text-dark mt-3">
                                    {
                                      saleDetails?.sale_details?.product_details
                                        ?.category
                                    }
                                  </div>
                                </div> */}
                        </div>
                        <table className="table align-middle table-row-dashed fs-6 gy-5 mb-6">
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      Item Price
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-dark">
                                  {/* {
                                      saleDetails?.sale_details?.product_details
                                        ?.price
                                    } */}
                                  <CurrencyFormat
                                    value={saleDetails?.sale_details?.product_details?.price.toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₱"}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      Service Fee
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-dark">
                                  ₱0.00
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-0">
                                <div className="d-flex align-items-center">
                                  <div className="ms-5">
                                    <div className="fw-bold mt-5 text-dark">
                                      TOTAL
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end py-0">
                                <div className="fw-bold mt-5 text-primary ">
                                  <CurrencyFormat
                                    value={totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₱"}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Wrapper */}
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
}
