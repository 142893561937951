import { useState, useEffect, ChangeEvent } from "react";
import { Link, useParams } from "react-router-dom";

import { AuthHeader } from "./header/AuthHeader";
import { database } from "../../../../firebase";
import { ref, get, child } from "firebase/database";
import { GuestLogin } from "../../../../_metronic/partials";
import { Button, Form } from "react-bootstrap-v5";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import { isMobileDevice } from "../../../../_metronic/assets/ts/_utils";

export function Product() {
  const CurrencyFormat = require("react-currency-format");
  const { product, reseller } =
    useParams<{ product: string; reseller: string }>();
  const [productDetails, setProductDetails] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  // get the rtdb reference
  const databaseRef = ref(database);

  const isMobile = isMobileDevice();
  useEffect(() => {
    get(child(databaseRef, "products/active/" + product)).then(
      (snapshot): any => {
        if (!snapshot.exists()) {
          // product no longer active
          window.location.href = "/auth/product-not-available";
          return;
        }

        get(
          child(
            databaseRef,
            "business_resellers/" + snapshot.val()?.owner + "/" + reseller
          )
        ).then((businessResellerSnap): any => {
          if (!businessResellerSnap.exists()) {
            // user is not a business member
            window.location.href = "/auth/product-not-available";
            return;
          }
        });

        const carouselImages = Object.values(snapshot.val().images);

        // set the product details
        setProductDetails(snapshot.val());

        var images = carouselImages;
        setCarousel(images);
      }
    );
  }, [databaseRef, product, reseller]);

  const [carousel, setCarousel] = useState(null);

  const images = carousel;

  // quantity button
  // State to store count values
  const [count, setCount] = useState("1");

  // Function to increment count by 1
  const incrementCount = () => {
    if (Number(count) >= 1) {
      // Update state with incremented value
      setCount(`${Number(count) + 1}`);
    } else {
      setCount("1");
    }
  };
  // Function to increment count by 1
  const decrementCount = () => {
    if (Number(count) <= 1) {
      setCount("1");
    } else {
      // Update state with incremented value
      setCount(`${Number(count) - 1}`);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const pattern = /[!@#$%^&*()\-_,./]+$/;
    const matches = value.match(pattern);
    if (value === "") {
      setCount("");
    }
    if (!/[0-9]/.test(value)) {
      return;
    }
    if (!isNaN(Number(value)) && !matches) {
      Number(value) > productDetails?.quantity
        ? alert(
            "Order quantity cannot exceed stock quantity, you can only order " +
              productDetails?.quantity
          )
        : setCount(value);
    } else {
      alert("Quantity should be in whole number format");
    }
  };
  const renderProceed = () => (
    <>
      <div className="d-flex-xs">
        <div className="fw-bold text-dark pe-3 fs-6 text-center mt-5 mb-1 mt-3-xs">
          Quantity
        </div>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={decrementCount}
            disabled={Number(count) <= 1}
            className="btn btn-icon btn-primary btn-sm"
          >
            -
          </button>

          <Form.Control
            type="tel"
            min={1}
            defaultValue={count}
            value={count}
            className="btn text-dark bg-white btn-sm"
            style={{ width: 80 }}
            onChange={handleChange}
            pattern="[0-9]*\.?[0-9]*"
            autoFocus
          />

          <button
            type="button"
            onClick={incrementCount}
            className="btn btn-icon btn-primary btn-sm"
            disabled={count >= productDetails?.quantity}
          >
            +
          </button>
        </div>
        &emsp;
        <button
          type="submit"
          className="btn btn-lg btn-primary w-90 mb-5 mt-5 w-100"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_guest_login"
          id=""
          disabled={count > productDetails?.quantity || Number(count) === 0}
        >
          Buy
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </>
  );
  const renderMobileProceed = () => (
    <>
      <div
        className="d-flex-xs"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            onClick={decrementCount}
            disabled={Number(count) <= 1}
            className="btn btn-icon btn-primary btn-sm"
          >
            -
          </button>

          <Form.Control
            type="tel"
            min={1}
            defaultValue={count}
            value={count}
            className="btn text-dark bg-white btn-sm"
            style={{ width: 60 }}
            onChange={handleChange}
            pattern="[0-9]*\.?[0-9]*"
            autoFocus
          />

          <button
            type="button"
            onClick={incrementCount}
            className="btn btn-icon btn-primary btn-sm"
            disabled={count >= productDetails?.quantity}
          >
            +
          </button>
        </div>
        &emsp;
        <button
          type="submit"
          className="btn btn-lg btn-primary w-90 mb-5 mt-5 w-75"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_guest_login"
          id=""
          disabled={count > productDetails?.quantity || Number(count) === 0}
        >
          Buy
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </>
  );

  const renderSeeMore = () => {
    if (productDetails?.description?.length > 100) {
      return seeMore ? (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          ... See Less
        </a>
      ) : (
        <a href="#!" onClick={() => setSeeMore(!seeMore)}>
          ... See More
        </a>
      );
    }
    return productDetails?.description;
  };

  return (
    <div>
      <AuthHeader />
      <GuestLogin
        product={product}
        reseller={reseller}
        quantity={Number(count)}
      />
      <Link to={"/product-not-available"} className="hide">
        Product Not Found
      </Link>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed product-container">
        {/* begin::Content */}
        <div className=" pb-lg-20 ps-4 py-5 p-sm-xs-0">
          {/* begin::Wrapper */}
          <div className="w-lg-800px w-md-1000px bg-white rounded  p-10 p-lg-15 mx-auto p-sm-xs-0">
            <div className="d-flex flex-column flex-column-fluid bgi-pos ition-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
              {/* begin::Content */}
              <div className="px-2 text-center">
                <div className="col-md-9 m-auto padding-right-0">
                  <div className=" me-md-6 ">
                    <Swiper
                      style={{
                        background: "#000000",
                      }}
                      spaceBetween={30}
                      centeredSlides={true}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      {images?.map((img, id) => (
                        <SwiperSlide
                          key={id}
                          style={{
                            width: "100%",
                            height: 500,
                          }}
                        >
                          <img height={500} src={img} alt="" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className="m-0 text-center text-left-xs px-5">
                    <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3 hidden">
                      {productDetails?.category}
                    </div>
                    <div className="fs-5 text-dark fw-bolder lh-base mt-3">
                      {productDetails?.name}
                    </div>
                    <span className="fw-semibold text-primary pe-3 fs-6 text-center mt-5">
                      <CurrencyFormat
                        value={productDetails?.price?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₱"}
                      />
                    </span>
                    <div
                      className="fw-semibold fs-7 text-dark mt-3 mb-5"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {productDetails?.description?.length > 100 && seeMore
                        ? productDetails?.description
                        : `${productDetails?.description?.slice(0, 100)} `}
                      {renderSeeMore()}
                    </div>
                    {/* <QuantityTicker /> */}
                    {productDetails?.quantity > 0 ? (
                      isMobile ? (
                        renderMobileProceed()
                      ) : (
                        renderProceed()
                      )
                    ) : (
                      <Button
                        variant="secondary"
                        className="w-100 mt-10"
                        disabled
                      >
                        Out of Stock
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
}
