/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from "react";
import { database, firestore } from "../../../../firebase";
import { ref, orderByChild, equalTo, onValue, query } from "firebase/database";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Invoice } from "./Invoice";
import { moneyFormatter } from "../../../utils/formatter";
import "react-data-table-component-extensions/dist/index.css";
import { KTSVG } from "../../../../_metronic/helpers";
import { collection, getDocs } from "firebase/firestore/lite";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";

type Props = {
  className: string;
};
const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
};
const TablesSalesInvoice = ({ className }: Props) => {
  const [allOrdersList, setAllOrderList] = useState<any[]>([]);
  const [ordersList, setOrderList] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [open, setOpen] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [filterPaid, setFilterPaid] = useState(true);
  const [filterDelivered, setFilterDelivered] = useState(true);
  const [filteredFc, setFilteredFc] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  let authId = localStorage.getItem("AUTH_ID");
  let fc_uid = localStorage.getItem("FC_UID");

  useEffect(() => {

    let topUserPostsRef
    if(fc_uid) {

      console.log("I'm here")
      topUserPostsRef = query(
        ref(database, "sale_transactions"),
        orderByChild("fulfillment_center_uid"),
        equalTo(`${fc_uid}`)
      );
  
    } else {

      console.log("I'm here2")
    topUserPostsRef = query(
      ref(database, "sale_transactions"),
      orderByChild("business_uid"),
      equalTo(authId)
    );

    }

    onValue(topUserPostsRef, (snapshot) => {
      const newOrderList: any[] = [];
      snapshot.forEach((childSnapshot) => {
        const so = childSnapshot.val();
        let name = "";
        if (so.user_details === undefined) {
          name = "";
        } else {
          if (so.user_details.display_name === undefined) {
            name = so.user_details.first_name + " " + so.user_details.last_name;
          } else {
            name = so.user_details.display_name;
          }
          let a = moment(so.created_at);
          newOrderList.push({
            key: childSnapshot.key,
            buyer_name: name,
            invoice_date: a.format("DD MMMM YYYY"),
            ...childSnapshot.val(),
          });
        }
      });

      setOrderList(
        newOrderList?.sort(
          (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
        ) || []
      );
      setAllOrderList(
        newOrderList?.sort(
          (a, b) => Date.parse(b.invoice_date) - Date.parse(a.invoice_date)
        ) || []
      );
      // setIsLoading(false);
    });
  }, [authId, fc_uid]);

  const columns = [
    {
      name: "Order No.",
      selector: (row) => row.usap_sale_uid,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.usap_sale_uid}
        </a>
      ),
    },
    {
      name: "Recipient",
      selector: (row) => row.buyer_name,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.buyer_name}
        </a>
      ),
    },
    {
      name: "Reseller",
      selector: (row) => row.reseller_details.name,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.reseller_details.name}
        </a>
      ),
    },
    {
      name: "Amount Due",
      selector: (row) => row.charge_amount,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row?.charge_amount ? moneyFormatter(row.charge_amount) : "0.00"}
        </a>
      ),
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoice_date,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.invoice_date}
        </a>
      ),
    },
    {
      name: "Fulfillment Center",
      selector: (row) => row?.fulfillment_center_details?.name,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row?.fulfillment_center_details?.name}
        </a>
      ),
    },
    {
      name: "Status",
      selector: (row) => (row.delivered ? "Delivered" : "Paid"),
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3 d-flex justify-content-center align-items-center w-100"
        >
          {row.delivered ? (
            <>
              <span className="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <span className="fw-bold d-block fs-7 text-dark">Delivered</span>
            </>
          ) : (
            <>
              <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
              <span className="fw-bold d-block fs-7 text-dark">Paid</span>
            </>
          )}
        </a>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.delivered,
      cell: (row) => {
        return (
          <a
            href="#!"
            className="btn bg-disabled btn-sm btn-light px-3  w-100"
            onClick={() => handleOpen(row.key)}
          >
            Mark as Delivered
          </a>
        );
      },
    },
  ];

  const resetFilter = () => {
    setFilterDateFrom("");
    setFilterDateTo("");
    setFilterDelivered(true);
    setFilterPaid(true);
    setFilteredFc({})
  };

  const dataFilter = () => {
    let filteredData = allOrdersList;

    if (filterDateFrom && filterDateTo) {
      filteredData = filteredData.filter((row) => {
        let createdDate = moment(row.created_at);
        let fromDate = moment(filterDateFrom);
        let toDate = moment(filterDateTo);
        return createdDate.isBetween(fromDate, toDate);
      });
    }

    if (filterDelivered && !filterPaid) {
      filteredData = filteredData.filter((row) => row.delivered);
    }
    if (!filterDelivered && filterPaid) {
      filteredData = filteredData.filter((row) => !row.delivered);
    }


    if(filteredFc?.name) {
      filteredData = filteredData.filter(row =>  row?.fulfillment_center_details?.name === filteredFc?.name)
    }
    setOrderList(filteredData);
  };

  const getFCs = async (): Promise<any[]> => {
    try {

        const res = await getDocs(collection(firestore, `users/${authId}/fulfillment_centers`));

        return res.docs.map(doc => ({...doc.data(), id: doc.id}))
    } catch (err) {
        return Promise.reject(err);
    }
  };


  const {data, isLoading} = useQuery(['fc'], getFCs, {
    initialData: []
  });

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5 align-self-end">
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user"
        >
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            className="btn btn-outline btn-outline-solid btn-outline-default btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen031.svg"
              className="svg-icon-3 text-gray-600"
            />
            Filter
          </a>
        </div>
      </div>
      <DataTableExtensions
        columns={columns}
        data={ordersList || []}
        print={false}
        export={false}
        filterPlaceholder="Search"
        filter={true}
      >
        <DataTable
          noHeader
          columns={columns}
          data={ordersList || []}
          customStyles={customStyles}
          highlightOnHover
          pagination
        />
      </DataTableExtensions>
      <Invoice onClose={handleClose} id={selectedId} open={open} />

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-sm modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id="staticBackdropLabel">
                Filter Options
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <h4 className="modal-title fs-5" id="staticBackdropLabel">
                  Short Filter
                </h4>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setFilterPaid(!filterPaid)}
                    checked={filterPaid}
                    id="paidCB"
                    readOnly
                  />
                  <label className="form-check-label text-dark">Paid</label>
                </div>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setFilterDelivered(!filterDelivered)}
                    checked={filterDelivered}
                    id="deliveredCB"
                    readOnly
                  />
                  <label className="form-check-label text-dark">
                    Delivered
                  </label>
                </div>
              </div>

              <div>
                <h4
                  className="modal-title fs-5 mb-2 mt-5"
                  id="staticBackdropLabel"
                >
                  Date Filter
                </h4>
                <h6>From</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateFrom(e.target.value)}
                  value={filterDateFrom}
                  id="deliveredCB"
                />
                <h6>To</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateTo(e.target.value)}
                  value={filterDateTo}
                  id="deliveredCB"
                  min={filterDateFrom}
                />


              </div>

             {!fc_uid && <div>
                <h6>Fullfilment Center</h6>
              <select
                value={filteredFc?.name || ''}
                onChange={(e) => 
                    {

                        const fcIndex = data.findIndex(fc => {
                          return fc.name === e.currentTarget.value
                        });
                        if(fcIndex > -1) {
                          setFilteredFc(data[fcIndex]);
                        }
                      }
                    }
                
                    className="form-check-input w-50 my-2" placeholder="Fullfilment Center" >
                {[{name: "---Select FC---"}, ...data]?.sort().map((option, index) => {
                  return <option key={index} >
                      {option.name}
                  </option>
                  })}
              </select>
            </div>}
            </div>
            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset
                </button>
                &emsp;
                <button
                  type="button"
                  onClick={() => dataFilter()}
                  className="btn btn-sm btn-primary"
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TablesSalesInvoice };
