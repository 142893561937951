/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { toAbsoluteUrl, KTSVG } from "../../../../_metronic/helpers";
import { AuthHeader } from "./header/AuthHeader";
export function EmptyState() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <AuthHeader />

      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed product-container">
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 mt-10">
          {/* begin::Logo */}
          <a href="#">
            {/* <KTSVG
              path={"/media/icons/ProductEmpty.svg"}
              className={"svg-icon-1 rotate-180"}
            /> */}
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/icons/ProductEmpty.svg")}
              className="empty-state"
            />
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div
            className="w-lg-500px bg-white rounded  p-10 p-lg-15 mx-auto"
            // style={{ width: "500px" }}
          >
            <div className="form w-100">
              {/* begin::Heading */}
              <div className="text-center mb-20">
                <h1 className="text-gray-400 fs-2">Ooops!</h1>
                <div className="text-gray-400 fw-bold fs-7">
                  Product is no longer available.
                </div>
              </div>

              {/* begin::Form group */}
              <div className="fv-row mb-10 pt-20">
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary w-100 mb-5"
                  >
                    {!loading && (
                      <a href="/" className="indicator-label text-white">
                        Go Home
                      </a>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>

                {/* end::Link */}
              </div>
              {/* end::Form group */}
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  );
}
