/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const EditCustomer: FC = () => {

    return (
        <div className='modal fade' id='kt_modal_edit_customer' aria-hidden='true'>
            <div className='modal-dialog  modal-fullscreen'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-20 mx-xl-120 pt-0 pb-15'>
                        <div className='text-left mb-13 pb-5 border-bottom'>
                            <h1 className='mb-3'>Edit Member</h1>

                        </div>

                        <div className='row g-6 g-xl-9 mb-12'>
                            <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">

                                <div className="card h-100 flex-center border-primary border border-dashed p-8">

                                    <img src="/metronic8/demo1/assets/media/svg/files/upload.svg" className="mb-5" alt="" />

                                    <a href="#" className="text-hover-primary fs-5 fw-bold mb-2">Add Photos/Videos</a>

                                    {/* <div className="fs-7 fw-semibold text-gray-400">Drag and drop files here</div> */}

                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">
                                <span className='text-dark fw-bolder text-hover-primary fs-6'> Upload a profile picture </span>
                                <span className='text-dark d-block fs-6'> Maximum upload size: 2MB</span>
                                <button id="" className="btn btn-primary me-3">Upload Photo</button>
                            </div>

                        </div>


                        <form id="kt_modal_new_target_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">First Name </span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="First Name" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Last Name </span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Last Name" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Middle Name</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Middle Name" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Email Address</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Email Address" name="" />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Mobile Number</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="+63" name="product_name" />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Country </span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Country" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Province </span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Province" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Municipality/City</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Municipality/City" name=" " />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Residence Address</span>
                                        </label>
                                        <textarea
                                            className='form-control '
                                            rows={1}
                                            placeholder='Number, Street, Barangay'
                                        ></textarea>

                                    </div>
                                </div>
                            </div>


                            <div className="text-center">
                                <button type="reset" id="kt_modal_new_target_cancel" className="btn btn-light-primary me-3">Cancel</button>
                                <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary">
                                    <span className="indicator-label">Save</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>


                        </form>
                    </div>





                </div>
            </div>
        </div>
    )
}

export { EditCustomer }
