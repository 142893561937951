/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
};

const TableReports: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <form
          data-kt-search-element="form"
          className=" mb-3 mt-4  align-items-start flex-column position-relative"
          autoComplete="off">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
          />
          <input
            type="text"
            className="form-control form-control-solid ps-10"
            name="search"
            placeholder="Search..."
            data-kt-search-element="input"
          />
        </form>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user">
          <a
            href="#"
            className="btn btn-outline btn-outline-solid btn-outline-default btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7">
            <KTSVG
              path="/media/icons/duotune/general/gen031.svg"
              className="svg-icon-3 text-gray-600"
            />
            Filter
          </a>
          <a
            href="#"
            className="btn  btn-primary px-6 "
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_product">
            Add Product
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive mb-5">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-150px">Transaction Number</th>
                <th className="min-w-150px">Customer</th>
                <th className="min-w-150px">Total Amount</th>
                <th className="min-w-150px ">Invoice No.</th>
                <th className="min-w-100px ">Transaction date</th>
                <th className="min-w-100px ">Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-5.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Kimberly Bado
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-3.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Alvin Soriano
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-19.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Nike Duns Low
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-11.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Joana Marie Estioco
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-18.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Alvin Soriano
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-dark d-block fs-6">TR123456</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src={toAbsoluteUrl("/media/avatars/300-20.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark d-block fs-6">
                        Jocel Layaan
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="text-dark d-block fs-6">₱150.00</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">12354</span>
                </td>
                <td>
                  <span className="text-dark d-block fs-6">1 May 2022</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
                    <span className="fw-bold d-block fs-7 text-dark">
                      {" "}
                      Paid{" "}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <div className="text-center pt-10 mb-10">
            <ul className="pagination">
              <li className="page-item previous">
                <a href="#" className="page-link">
                  <i className="previous"></i>
                </a>
              </li>

              <li className="page-item active">
                <a href="#" className="page-link">
                  1
                </a>
              </li>

              <li className="page-item">
                <a href="#" className="page-link">
                  2
                </a>
              </li>

              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>

              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>

              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>

              <li className="page-item">
                <a href="#" className="page-link">
                  6
                </a>
              </li>

              <li className="page-item next">
                <a href="#" className="page-link">
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TableReports };
