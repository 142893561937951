/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from "react";
import { ref, orderByChild, equalTo, onValue } from "firebase/database";
import {collection, deleteDoc, getDocs, doc, orderBy, query}  from "firebase/firestore/lite";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { KTSVG } from "../../../_metronic/helpers";
import { database } from "../../../firebase";
import { moneyFormatter } from "../../utils/formatter";
import { Invoice } from "../invoice/components/Invoice";
import { firestore } from "../../../firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../modules/api";
import { EditPromo } from "../../../_metronic/partials/modals/promo-deals/EditPromo";

type Props = {
  className: string;
};
const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
};
const FCTable = ({ className }: Props) => {
  const [allOrdersList, setAllOrderList] = useState<any[]>([]);
  const [ordersList, setOrderList] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [open, setOpen] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [filterPaid, setFilterPaid] = useState(true);
  const [filterDelivered, setFilterDelivered] = useState(true);

  const [toEdit, setToEdit] = useState<{id?: number}>({});

  const clearToEdit = () => {
    setToEdit({});
  }

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  let authId = localStorage.getItem("AUTH_ID");

  const getFCs = async () => {
    try {

      const collectionsRef = query(collection(firestore, `users/${authId}/fulfillment_centers`), orderBy('created_at', 'desc'))
        const res = await getDocs(collectionsRef);

        return res.docs.map(doc => ({...doc.data(), id: doc.id}))
    } catch (err) {
        return Promise.reject(err);
    }
  };

  const deleteFC = async (id) => {
    try {

        const res = await deleteDoc(doc(firestore, `users/${authId}/fulfillment_centers/${id}`));

        return res;
    } catch (err) {
        return Promise.reject(err);
    }
  };

  const {data, isLoading} = useQuery(['fc'], getFCs, {
    refetchOnMount: true
  });

  const {mutate} = useMutation(deleteFC, {
    onSettled: () => {
        queryClient.invalidateQueries(['fc']);
    }
  });


  const columns = [
    {
      name: "Fulfillment Center",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.name}
        </a>
      ),
    },
    {
      name: "Mobile Number",
      selector: (row) => row.phone_number,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.phone_number}
        </a>
      ),
    },
    {
      name: "Address",
      selector: (row) => `${row.address_1}, ${row.address_2}, ${row.barangay_district}, ${row.city_municipality}, ${row.state_province}, ${row.postal_code}`,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {`${row.address_1}, ${row.address_2 ? `${row.address_2},` : ''} ${row.barangay_district}, ${row.city_municipality}, ${row.state_province}, ${row.postal_code}`}
        </a>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row.charge_amount,
      sortable: true,
      cell: (row) => (
        <div className="btn text-dark btn-sm px-3  w-100">
                  <div
                    onClick={() => {
                        const response = window.confirm(
                           "Are you sure you want to delete this Fulfillment Center?"
                          )

                          if(response) {
                            mutate(row.id)
                          }
                    }}
                    className="btn btn-sm btn-light m-1">
                    <i className="bi bi-trash fs-1x"></i> Delete
                  </div>


                  <a
                   
                   href="#"
                   data-bs-toggle="modal"
                   data-bs-target="#kt_modal_edit_promos_and_deals"
                   >

<div
onClick={() => {
  console.log("row: ", row)
  setToEdit(row)
}}
                    className="btn btn-sm btn-light m-1">
                   <i className="bi bi-pencil-square fs-1x"></i> Edit
                
                  </div>   </a>
        </div>
        
      ),
    },
   
  ];

  const resetFilter = () => {
    setFilterDateFrom("");
    setFilterDateTo("");
    setFilterDelivered(true);
    setFilterPaid(true);
  };

  const dataFilter = () => {
    let filteredData = allOrdersList;

    if (filterDateFrom && filterDateTo) {
      filteredData = filteredData.filter((row) => {
        let createdDate = moment(row.created_at);
        let fromDate = moment(filterDateFrom);
        let toDate = moment(filterDateTo);
        return createdDate.isBetween(fromDate, toDate);
      });
    }

    if (filterDelivered && !filterPaid) {
      filteredData = filteredData.filter((row) => row.delivered);
    }
    if (!filterDelivered && filterPaid) {
      filteredData = filteredData.filter((row) => !row.delivered);
    }

    setOrderList(filteredData);
  };

  if(isLoading) return null;


  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5 align-self-end">
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user"
        >
          {/* <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            className="btn btn-outline btn-outline-solid btn-outline-default btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen031.svg"
              className="svg-icon-3 text-gray-600"
            />
            Filter
          </a> */}
          <a
            href="#"
            className="btn  btn-primary px-6 "
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_promos_and_deals"
          >
            Add Fulfillment Center
          </a>
        </div>
      </div>
      <DataTableExtensions
        columns={columns}
        data={data}
        print={false}
        export={false}
        filterPlaceholder="Search"
        filter={true}
      >
        <DataTable
          noHeader
          columns={columns}
          data={ordersList || []}
          customStyles={customStyles}
          highlightOnHover
          pagination
        />
      </DataTableExtensions>
      {/* <Invoice onClose={handleClose} id={selectedId} open={open} /> */}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-sm modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id="staticBackdropLabel">
                Filter Options
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <h4 className="modal-title fs-5" id="staticBackdropLabel">
                  Short Filter
                </h4>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setFilterPaid(!filterPaid)}
                    checked={filterPaid}
                    id="paidCB"
                    readOnly
                  />
                  <label className="form-check-label text-dark">Paid</label>
                </div>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setFilterDelivered(!filterDelivered)}
                    checked={filterDelivered}
                    id="deliveredCB"
                    readOnly
                  />
                  <label className="form-check-label text-dark">
                    Delivered
                  </label>
                </div>
              </div>

              <div>
                <h4
                  className="modal-title fs-5 mb-2 mt-5"
                  id="staticBackdropLabel"
                >
                  Date Filter
                </h4>
                <h6>From</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateFrom(e.target.value)}
                  value={filterDateFrom}
                  id="deliveredCB"
                />
                <h6>To</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateTo(e.target.value)}
                  value={filterDateTo}
                  id="deliveredCB"
                  min={filterDateFrom}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset
                </button>
                &emsp;
                <button
                  type="button"
                  onClick={() => dataFilter()}
                  className="btn btn-sm btn-primary"
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <EditPromo {...toEdit} />
    </div>
  );
};

export { FCTable };
