/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

const InvoiceDetails: FC = () => {
  return (
    <div
      className="modal fade"
      id="kt_modal_invoice_details"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-480px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-xl-10 pt-0 pb-15">
            <div className="text-left ">
              <h1 className="mb-3">
                Invoice
                <span> #000991</span>
              </h1>
            </div>

            <div className="separator d-flex flex-center mb-8"></div>

            <div className="d-flex flex-stack">
              <div className="text-dark fw-bolder text-hover-primary fs-6">
                22 May 2022
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
                <span className="fw-bold d-block fs-7 text-dark"> Unpaid </span>
              </div>
            </div>
            <br></br>

            <div className="text-dark fw-bolder fs-6">Recipient Details</div>

            <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
              <div className="row">
                <div className="col-md-2 col-sm-2 col-xs-12">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        className="symbol symbol-circle"
                        src={toAbsoluteUrl("/media/stock/600x400/img-9.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-12">
                  <div className="card-body pt-0 ps-1 text-dark">
                    <div className="text-dark fw-bolder fs-6">
                      Kimberly Bado
                    </div>
                    Unit Hastings Road, Melbourne,
                    <br></br>Victoria, Australia
                    <br></br>+6300872625576
                    <br></br>alexandra@gmail.com
                    <br></br>1 May 1990
                  </div>
                </div>
              </div>

              <div className="text-dark fw-bolder fs-6">Products</div>

              <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="py-0">
                      <div className="d-flex align-items-center">
                        <a
                          href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/edit-product.html"
                          className="symbol symbol-40px"
                        >
                          <img
                            className="symbol"
                            src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                            alt=""
                          />{" "}
                        </a>
                        <div className="ms-5">
                          <div className="fw-bold mt-5 text-dark">
                            Nike Dunk
                          </div>
                          <div className="text-gray-600">ID-45453423</div>
                        </div>
                      </div>
                    </td>
                    <td className="text-end py-0">
                      <div className="fw-bold mt-5 text-dark">₱150.00</div>
                      <div className="text-gray-600">x1</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-0">
                      <div className="d-flex align-items-center">
                        <a
                          href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/edit-product.html"
                          className="symbol symbol-40px"
                        >
                          <img
                            className="symbol"
                            src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                            alt=""
                          />{" "}
                        </a>
                        <div className="ms-5">
                          <div className="fw-bold mt-5 text-dark">
                            New Balance 540
                          </div>
                          <div className="text-gray-600">ID-45453423</div>
                        </div>
                      </div>
                    </td>
                    <td className="text-end py-0">
                      <div className="fw-bold mt-5 text-dark">₱1,250.00</div>
                      <div className="text-gray-600">x1</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-0">
                      <div className="d-flex align-items-center">
                        <a
                          href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/edit-product.html"
                          className="symbol symbol-40px"
                        >
                          <img
                            className="symbol"
                            src={toAbsoluteUrl("/media/avatars/300-14.jpg")}
                            alt=""
                          />{" "}
                        </a>
                        <div className="ms-5">
                          <div className="fw-bold mt-5 text-dark">Yeezy</div>
                          <div className="text-gray-600">ID-45453423</div>
                        </div>
                      </div>
                    </td>
                    <td className="text-end py-0">
                      <div className="fw-bold mt-5 text-dark">₱11,250.00</div>
                      <div className="text-gray-600">x1</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center">Subtotal :</td>
                    <td className="text-end">₱12,264.00</td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center">Discount :</td>
                    <td className="text-end">₱0.00</td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center">
                      Shipping Fee :
                    </td>
                    <td className="text-end">₱0.00</td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center fw-bold text-dark">
                      Subtotal :
                    </td>
                    <td className="text-end fw-bold text-dark">₱13,450.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <label className="form-check form-switch form-check-custom form-check-solid">
              <input className="form-check-input" type="checkbox" value="1" />
              <span className="form-check-label fw-semibold text-muted">
                Mark as Delivered
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InvoiceDetails };
