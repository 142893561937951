import axios from 'axios'
import {UserModel} from '../models/UserModel'
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";

const API_URL = process.env.REACT_APP_API_URL
const USAP_URL = process.env.REACT_APP_USAP_API_URL
const BIZ_API_URL = process.env.REACT_APP_BIZ_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${BIZ_API_URL}/verify-token`
export const LOGIN_URL = `${BIZ_API_URL}/login`
export const BUSINESS_LOGIN_URL = `${USAP_URL}/users/login-fc-user`
export const REGISTER_URL = `${BIZ_API_URL}/register`
export const REQUEST_PASSWORD_URL = `${BIZ_API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
 let login = axios.post(LOGIN_URL, {
    "username": username,
    "password": password,
  })
  login.then( data => {
      // console.log(data);
      let email = username + "@usap.com.ph";
      // console.log(email);
      authFirebase(email,password);
    }
  )
  return login;
}

export const loginAsBusiness =  async (params: {username: string, password: string, business_id: string}) => {
  try {
    let loginResponse: any = await axios.post(BUSINESS_LOGIN_URL, params)
     
    console.log("Login: ", loginResponse)
    const auth = getAuth();
    signInWithCustomToken(auth, loginResponse.data.token)
    //   .then((userCredential) => {
    //     localStorage.setItem("AUTH_ID",userCredential.user.uid)
    //     // console.log(userCredential);
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });

    localStorage.setItem("FC_UID", loginResponse.data.fc_uid)
   
   return loginResponse;
  } catch (err) {
    return Promise.reject(err);
  }
 }

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token:string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token:token
  })
}

export function xenditCreateToken(details:any) {
  let postData = {
    'amount': details.amount,
    "card_data": {
      "account_number": details.card_number,
      "exp_month": details.exp_month,
      "exp_year": details.exp_year
    },
    "card_cvn": details.card_cvn,
    "is_multiple_use": false,
    "should_authenticate": true,
  }
  return axios.post(API_URL + '/xendit/webCheckoutChargeCard', postData)
}

//Firebase Authentication getting UID
export function authFirebase(email: string, password: string){
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      localStorage.setItem("AUTH_ID",userCredential.user.uid)
      // console.log(userCredential);
    })
    .catch((error) => {
      // console.log(error);
    });
}