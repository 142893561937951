/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from "react";
import { database } from "../../../firebase";
import { ref, orderByChild, equalTo, onValue, query } from "firebase/database";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Invoice } from "../invoice/components/Invoice";
import { moneyFormatter } from "../../utils/formatter";
import "react-data-table-component-extensions/dist/index.css";
import { KTSVG } from "../../../_metronic/helpers";

type Props = {
  className: string;
  resellerUID: string;
  triggerScan: VoidFunction;
};
const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
};
const TablesSalesInvoice = ({ className, resellerUID, triggerScan }: Props) => {
  const [allOrdersList, setAllOrderList] = useState<any[]>([]);
  const [ordersList, setOrderList] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [open, setOpen] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [filterPaid, setFilterPaid] = useState(true);
  const [filterDelivered, setFilterDelivered] = useState(true);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  let authId = localStorage.getItem("AUTH_ID");

  useEffect(() => {
    // setIsLoading(true);
    const topUserPostsRef = query(
      ref(database, "sale_transactions"),
      orderByChild("business_reseller_key"),
      equalTo(`${authId}_${resellerUID}`)
    );

    onValue(topUserPostsRef, (snapshot) => {
      const newOrderList: any[] = [];
      snapshot.forEach((childSnapshot) => {
        const so = childSnapshot.val();
        let name = "";
        if (so?.user_details === undefined) {
          name = "";
        } else {
          if (so.user_details.display_name === undefined) {
            name = so.user_details.first_name + " " + so.user_details.last_name;
          } else {
            name = so.user_details.display_name;
          }
          let a = moment(so.created_at);
          if (!so?.delivered) {
            newOrderList.push({
              key: childSnapshot.key,
              buyer_name: name,
              invoice_date: a.format("DD MMMM YYYY"),
              ...childSnapshot.val(),
            });
          }
        }
      });

      setOrderList(
        newOrderList?.sort(
          (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
        ) || []
      );
      setAllOrderList(
        newOrderList?.sort(
          (a, b) => Date.parse(b.invoice_date) - Date.parse(a.invoice_date)
        ) || []
      );
      // setIsLoading(false);
    });
  }, [authId]);

  const columns = [
    {
      name: "Order No.",
      selector: (row) => row.usap_sale_uid,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.usap_sale_uid}
        </a>
      ),
    },
    {
      name: "Recipient",
      selector: (row) => row.buyer_name,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.buyer_name}
        </a>
      ),
    },
    {
      name: "Amount Due",
      selector: (row) => row.charge_amount,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row?.charge_amount ? moneyFormatter(row.charge_amount) : "0.00"}
        </a>
      ),
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoice_date,
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3  w-100"
        >
          {row.invoice_date}
        </a>
      ),
    },
    {
      name: "Status",
      selector: (row) => (row.delivered ? "Delivered" : "Paid"),
      sortable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={() => handleOpen(row.key)}
          className="btn text-dark btn-sm px-3 d-flex justify-content-center align-items-center w-100"
        >
          {row.delivered ? (
            <>
              <span className="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <span className="fw-bold d-block fs-7 text-dark">Delivered</span>
            </>
          ) : (
            <>
              <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
              <span className="fw-bold d-block fs-7 text-dark">Paid</span>
            </>
          )}
        </a>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.delivered,
      cell: (row) => {
        return (
          <a
            href="#!"
            className="btn bg-disabled btn-sm btn-light px-3  w-100"
            onClick={() => handleOpen(row.key)}
          >
            Mark as Delivered
          </a>
        );
      },
    },
  ];

  const resetFilter = () => {
    setFilterDateFrom("");
    setFilterDateTo("");
    setFilterDelivered(true);
    setFilterPaid(true);
  };

  const dataFilter = () => {
    let filteredData = allOrdersList;

    if (filterDateFrom && filterDateTo) {
      filteredData = filteredData.filter((row) => {
        let createdDate = moment(row.created_at);
        let fromDate = moment(filterDateFrom);
        let toDate = moment(filterDateTo);
        return createdDate.isBetween(fromDate, toDate);
      });
    }

    if (filterDelivered && !filterPaid) {
      filteredData = filteredData.filter((row) => row.delivered);
    }
    if (!filterDelivered && filterPaid) {
      filteredData = filteredData.filter((row) => !row.delivered);
    }

    setOrderList(filteredData);
  };

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5 align-self-center align-items-center">
        <h1>{ordersList?.[0] && ordersList[0]?.reseller_details?.name}</h1>
        {resellerUID && resellerUID != "none" ? (
          <h5 className="text-gray-600">
            &emsp; /&emsp;{" "}
            {ordersList?.[0] && ordersList[0]?.reseller_details?.email}
            &emsp;/&emsp;
          </h5>
        ) : (
          <h5 className="text-gray-600">Scan to fetch orders</h5>
        )}
        <h6 className="text-gray-400">
          {ordersList?.[0] && ordersList[0]?.reseller_details?.mobile}
        </h6>
      </div>
      <div className="card-header border-0 pt-5 align-self-end">
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to Scan QR Code"
          onClick={() => triggerScan()}
        >
          <a
            href="#"
            className="btn btn-outline btn-color-primary btn-outline-solid btn-outline-default px-6 align-self-center text-nowrap me-3 text-gray-800 fs-7"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen025.svg"
              className="svg-icon-3 text-gray-600"
            />
            QR CODE SCANNER
          </a>
        </div>
      </div>
      <DataTableExtensions
        columns={columns}
        data={ordersList || []}
        print={false}
        export={false}
        filterPlaceholder="Search"
        filter={true}
      >
        <DataTable
          noHeader
          columns={columns}
          data={ordersList || []}
          customStyles={customStyles}
          highlightOnHover
          pagination
        />
      </DataTableExtensions>
      <Invoice onClose={handleClose} id={selectedId} open={open} />

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-sm modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id="staticBackdropLabel">
                Filter Options
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <h4 className="modal-title fs-5" id="staticBackdropLabel">
                  Short Filter
                </h4>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={() => setFilterPaid(!filterPaid)}
                    checked={filterPaid}
                    id="paidCB"
                  />
                  <label className="form-check-label text-dark">Paid</label>
                </div>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={() => setFilterDelivered(!filterDelivered)}
                    checked={filterDelivered}
                    id="deliveredCB"
                  />
                  <label className="form-check-label text-dark">
                    Delivered
                  </label>
                </div>
              </div>

              <div>
                <h4
                  className="modal-title fs-5 mb-2 mt-5"
                  id="staticBackdropLabel"
                >
                  Date Filter
                </h4>
                <h6>From</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateFrom(e.target.value)}
                  value={filterDateFrom}
                  id="deliveredCB"
                />
                <h6>To</h6>
                <input
                  className="form-check-input w-50 my-2"
                  type="date"
                  onChange={(e) => setFilterDateTo(e.target.value)}
                  value={filterDateTo}
                  id="deliveredCB"
                  min={filterDateFrom}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset
                </button>
                &emsp;
                <button
                  type="button"
                  onClick={() => dataFilter()}
                  className="btn btn-sm btn-primary"
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TablesSalesInvoice };
