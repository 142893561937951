/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
interface AddProduct {
  productName: string;
  category: string;
  commisionType: string;
  commision: string;
  price: string;
  qty: string;
  description: string;
}

const inits: AddProduct = {
  productName: "",
  category: "1",
  commisionType: "1",
  commision: "",
  price: "",
  qty: "",
  description: "",
};

const addProductSchema = [
  Yup.object({
    productName: Yup.string().required().label("Product name"),
    category: Yup.string().required().label("Category"),
    price: Yup.string().required().label("Price"),
    commisionType: Yup.string().required().label("Commision Type"),
    commision: Yup.string().required().label("Commision"),
    qty: Yup.string().required().label("QTY"),
    description: Yup.string().required().label("Description"),
  }),
];
const submitAddProduct = (values: AddProduct, actions: FormikValues) => {};
const AddProduct: FC = () => {
  const [currentSchema, setCurrentSchema] = useState(addProductSchema[0]);
  const [initValues] = useState<AddProduct>(inits);

  return (
    <div className="modal fade" id="kt_modal_add_product" aria-hidden="true">
      <div className="modal-dialog  modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal">
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-20 mx-xl-120 pt-0 pb-15">
            <div className="text-left mb-13 pb-5 border-bottom">
              <h1 className="mb-3">Add Product</h1>
            </div>

            <div className="row g-6 g-xl-9 mb-12">
              <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">
                <div className="card h-100 flex-center border-primary border border-dashed p-8">
                  <img
                    src="/metronic8/demo1/assets/media/svg/files/upload.svg"
                    className="mb-5"
                    alt=""
                  />

                  <a href="#" className="text-hover-primary fs-5 fw-bold mb-2">
                    Add Photos/Videos
                  </a>

                  {/* <div className="fs-7 fw-semibold text-gray-400">Drag and drop files here</div> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">
                <img
                  src={toAbsoluteUrl(
                    "/media/USAPBIZ/LOGO/HORIZONTAL/USAP-02.png"
                  )}
                  alt=""
                  className="card w-100 h-100 flex-center border-primary border border-dashed p-8"
                />
              </div>
              <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">
                <img
                  src={toAbsoluteUrl(
                    "/media/USAPBIZ/LOGO/HORIZONTAL/USAP-02.png"
                  )}
                  alt=""
                  className="card w-100 h-100 flex-center border-primary border border-dashed p-8"
                />
              </div>
              <div className="col-md-4 col-lg-4 col-xl-3  col-sm-3">
                <img
                  src={toAbsoluteUrl(
                    "/media/USAPBIZ/LOGO/HORIZONTAL/USAP-02.png"
                  )}
                  alt=""
                  className="card w-100 h-100 flex-center border-primary border border-dashed p-8"
                />
              </div>
            </div>
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitAddProduct}>
              {() => (
                <Form className="form" noValidate id="kt_modal_new_target_form">
                  {/* <form
              id="kt_modal_new_target_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            > */}
                  <div className="fv-row mb-10">
                    <label className="d-flex align-items-center fs-6 fw-bold  text-dark">
                      <span className="required">Product Name</span>
                    </label>

                    <Field
                      type="text"
                      className="form-control form-control-lg"
                      name="productName"
                      placeholder="Product Name"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="productName" />
                    </div>
                  </div>

                  <div className="row g-6 g-xl-9 ">
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Category</span>
                      </label>

                      <div className="ms-2">
                        <select
                          defaultValue={"2"}
                          name="category"
                          className="form-select  form-select-lg select2-hidden-accessible form-control form-control-lg">
                          <option value="1">Category 1</option>
                          <option
                            value="2"
                            data-select2-id="select2-data-12-vz6w">
                            Category 2
                          </option>
                        </select>
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="category" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">QTY</span>
                      </label>

                      <Field
                        type="number"
                        pattern="^[0-9/]*$"
                        className="form-control form-control-lg"
                        name="qty"
                        placeholder="qty"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="qty" />
                      </div>
                    </div>
                  </div>
                  <div className="row g-6 g-xl-9 mb-9 mt-2">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Price</span>
                      </label>

                      <Field
                        type="number"
                        pattern="^[0-9/]*$"
                        className="form-control form-control-lg"
                        name="price"
                        placeholder="Price"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="price" />
                      </div>
                    </div>
                  </div>
                  <div className="row g-6 g-xl-9">
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Commision Type</span>
                      </label>

                      <div className="ms-2">
                        <select
                          defaultValue={"1"}
                          name="commisionType"
                          className="form-select  form-select-lg select2-hidden-accessible form-control form-control-lg">
                          <option value="1">Fixed</option>
                          <option
                            value="2"
                            data-select2-id="select2-data-12-vz6w">
                            Percentage
                          </option>
                        </select>
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="commisionType" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Commision</span>
                      </label>

                      <Field
                        type="number"
                        pattern="^[0-9/]*$"
                        className="form-control form-control-lg"
                        name="commision"
                        placeholder="Commision"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="commision" />
                      </div>
                    </div>
                  </div>

                  <div className="row g-6 g-xl-9 mt-2 ">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                          <span className="required">Description</span>
                        </label>
                        <textarea
                          className="form-control "
                          rows={3}
                          name="description"
                          placeholder="Description here ..."></textarea>
                        <div className="text-danger">
                          <ErrorMessage name="description" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mb-10">
                    <button
                      type="reset"
                      id="kt_modal_new_target_cancel"
                      className="btn btn-light-primary me-3"
                      data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_target_submit"
                      className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                  {/* </form> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddProduct };
