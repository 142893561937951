/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

import {
    TablePromosAndDeals
} from '../../../_metronic/partials/widgets'
import { FCTable } from './FCTable'

const PromosAndDeals: FC = () => (
    <>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-12'>
            <div className='col-xl-12'>

               <FCTable className="card-xl-stretch mb-xl-8"/>
            </div>
        </div>

        {/* end::Row */}

    </>
)

const PromosAndDealsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Fulfillment Centers</PageTitle>
            <PromosAndDeals />

        </>
    )
}

export { PromosAndDealsWrapper }