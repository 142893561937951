/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { RootState } from "../../setup";
import { MasterInit } from "../../_metronic/layout/MasterInit";
import { GuestCheckout } from "../modules/auth/components/GuestCheckout";
import { Product } from "../modules/auth/components/Product";
import { ProductPayment } from "../modules/auth/components/ProductPayment";
import { ProductPreview } from "../modules/auth/components/ProductPreview";
import { ProductReceipt } from "../modules/auth/components/ProductReceipt";
import { EmptyState } from "../modules/auth/components/EmptyState";
const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        <Route path="/product/:product/:reseller" component={Product} />
        <Route
          path="/product-preview/:product/:reseller/:quantity"
          component={ProductPreview}
        />
        <Route path="/product-payment" component={ProductPayment} />
        <Route path="/product-receipt/:sale_uid" component={ProductReceipt} />
        <Route
          path="/guest-checkout/:product/:reseller/:quantity"
          component={GuestCheckout}
        />
        <Route path="/product-not-available" component={EmptyState} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };
