/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {TablesSalesInvoice} from "./components/TableList"
// import { TablesSalesInvoice } from "../../../_metronic/partials/widgets";

const SalesInvoicePage: FC = () => (
  <>
    {/* begin::Row */}
    <div className="row gy-5 g-xl-12">
      <div className="col-xl-12">
        <TablesSalesInvoice className="card-xl-stretch mb-xl-8" />
      </div>
    </div>
    {/* end::Row */}
  </>
);

const SalesInvoiceWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Sales Order</PageTitle>
      <SalesInvoicePage />
    </>
  );
};

export { SalesInvoiceWrapper };
