/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface EditInvoice {
  invoiceNo: string;
  invoiceDate: DatePicker;
  recipient: string;
  productName: string;
  price: string;
  qty: string;
  description: string;
}

const inits: EditInvoice = {
  invoiceNo: "",
  invoiceDate: "",
  recipient: "",
  productName: "",
  price: "",
  qty: "",
  description: "",
};

const EditInvoiceSchema = [
  Yup.object({
    invoiceNo: Yup.string().required().label("Sales Order."),
    invoiceDate: Yup.date().required().label("Sales Order Date"),
    price: Yup.string().required().label("Price"),
    recipient: Yup.string().required().label("Recipient"),
    productName: Yup.string().required().label("Product Name"),
    qty: Yup.string().required().label("QTY"),
  }),
];
const submitEditInvoice = (values: EditInvoice, actions: FormikValues) => {
  alert(1);
};
const EditInvoice: FC = () => {
  const [currentSchema, setCurrentSchema] = useState(EditInvoiceSchema[0]);
  const [initValues] = useState<EditInvoice>(inits);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="modal fade" id="kt_modal_edit_invoice" aria-hidden="true">
      <div className="modal-dialog mw-900px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal">
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-left ">
              <h1 className="mb-3">Invoice</h1>
            </div>

            <div className="separator d-flex flex-center mb-8"></div>
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitEditInvoice}>
              {() => (
                <Form className="form" noValidate id="kt_modal_new_target_form">
                  {/* <form
              id="kt_modal_new_target_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            > */}
                  <div className="row g-6 g-xl-9 mt-2">
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Sales Order</span>
                      </label>

                      <Field
                        type="text"
                        className="form-control form-control-lg"
                        name="invoiceNo"
                        placeholder="Sales Order"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="invoiceNo" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Sales Order Date</span>
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        className="form-control  form-control-lg"
                        name="invoiceDate"
                      />
                      {/* <Field
                        type="date"
                        className="form-control form-control-lg"
                        name="invoiceDate"
                        placeholder="Recipient Name"
                      /> */}
                      <div className="text-danger">
                        <ErrorMessage name="invoiceDate" />
                      </div>
                    </div>
                  </div>
                  <div className="fv-row mb-10">
                    <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                      <span className="required">Recipient</span>
                    </label>

                    <Field
                      type="text"
                      className="form-control form-control-lg"
                      name="recipient"
                      placeholder="Recipient Name"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="recipient" />
                    </div>
                  </div>

                  <div className="row g-6 g-xl-9 mb-9">
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Product Name</span>
                      </label>

                      <Field
                        type="number"
                        className="form-control form-control-lg"
                        name="productName"
                        placeholder="Product Name"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="price" />
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                        <span className="required">Price</span>
                      </label>

                      <Field
                        type="number"
                        pattern="^[0-9/]*$"
                        className="form-control form-control-lg"
                        name="price"
                        placeholder="Price"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="price" />
                      </div>
                    </div>
                  </div>

                  <a href="#" className="btn btn-primary me-2 mb-2">
                    <i className="las la-plus fs-2 me-2"></i>Add Product
                  </a>

                  <div className="card card-flush py-4 flex-row-fluid border rounded my-3">
                    <div className="card-body py-0">
                      <div className="table-responsive">
                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                          <tbody className="fw-semibold">
                            <tr>
                              <td className="text-muted">
                                <div className="d-flex align-items-center">
                                  <label className="fs-6 fw-semibold form-label mt-3">
                                    <span>Sub Total</span>
                                  </label>
                                </div>
                              </td>
                              <td className="fw-bold text-end">
                                <div className="fs-6 fw-semibold form-label mt-3">
                                  <span>150.00</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                  <span>Discount</span>
                                </label>
                              </td>
                              <td className="fw-bold text-end">
                                <a
                                  href="#"
                                  className="btn btn-primary me-2 mb-2 p-3">
                                  <i className="las la-plus fs-2 me-2"></i>Add
                                  Discount
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                  <span>Shipping Fee</span>
                                </label>
                              </td>
                              <td className="fw-bold text-end">
                                <a
                                  href="#"
                                  className="btn btn-primary me-2 mb-2 p-3">
                                  <i className="las la-plus fs-2 me-2"></i>Add
                                  Shipping
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td className="">
                                <label className="fs-6 fw-bold form-label mt-3">
                                  <span className="fw-bold">Sub Total</span>
                                </label>
                              </td>
                              <td className="fw-bold text-end">
                                <div className="fs-6 fw-semibold form-label mt-3">
                                  <span>150.00</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="mb-0">
                    <label className="form-label fs-6 fw-bold text-gray-700">
                      Message
                    </label>
                    <textarea
                      className="form-control "
                      rows={3}
                      placeholder="Message here ..."></textarea>
                  </div>
                  <br></br>

                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                    />
                    <span className="form-check-label fw-semibold text-muted">
                      Mark as Delivered
                    </span>
                  </label>

                  <div className="text-center">
                    <button
                      type="reset"
                      id="kt_modal_new_target_cancel"
                      className="btn btn-light-primary me-3"
                      data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_target_submit"
                      className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                  {/* </form> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditInvoice };
