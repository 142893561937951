/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../helpers";
import { Link } from "react-router-dom";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
interface EditCustomerDetails {
  fullName: string;
  city: string;
  address: string;
  email: string;
  phoneNum: string;
}

const inits: EditCustomerDetails = {
  fullName: "",
  city: "1",
  address: "",
  email: "",
  phoneNum: "",
};
const phoneRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EditCustomerDetailsSchema = [
  Yup.object({
    fullName: Yup.string().required().label("Full Name"),
    city: Yup.string().required().label("City / Municipality"),
    email: Yup.string().required().label("Email Address"),
    address: Yup.string().required().label("Address"),
    // phoneNum: Yup.string().required().label("Phone Number"),
    phoneNum: Yup.string()
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required")
      .min(10, "to short")
      .max(11, "to long"),
  }),
];

const submitEditCustomerDetails = (
  values: EditCustomerDetails,
  actions: FormikValues
) => {};

const EditCustomerDetails: FC = () => {
  const [loading, setLoading] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(
    EditCustomerDetailsSchema[0]
  );
  const [initValues] = useState<EditCustomerDetails>(inits);
  return (
    <div
      className="modal fade"
      id="kt_modal_edit_customer_dets"
      aria-hidden="true">
      <div className="modal-dialog   mw-480px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal">
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-xl-120 pt-0 pb-15">
            <div className="center px-5" data-kt-subscriptions-form="pricing">
              <div className="card-body p-0">
                <div className="text-center">
                  <h1 className="text-dark mb-3">Edit Name and Address</h1>
                </div>

                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitEditCustomerDetails}>
                  {() => (
                    <Form
                      className="form"
                      noValidate
                      id="kt_modal_new_target_form">
                      <div className="fv-row mb-10">
                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                          <span className="required">Full Name</span>
                        </label>

                        <Field
                          type="text"
                          className="form-control form-control-lg"
                          name="fullName"
                          placeholder="Full Name"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="fullName" />
                        </div>
                      </div>
                      <div className="fv-row mb-10">
                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                          <span className="required">Address</span>
                        </label>

                        <Field
                          type="text"
                          className="form-control form-control-lg"
                          name="address"
                          placeholder="Address"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="address" />
                        </div>
                      </div>

                      <div className="row mb-10 ">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                            <span className="required">
                              City / Municipality
                            </span>
                          </label>

                          <div className="ms-2">
                            <select
                              defaultValue={"2"}
                              name="city"
                              className="form-select  form-select-lg select2-hidden-accessible form-control form-control-lg">
                              <option value="1">city 1</option>
                              <option
                                value="2"
                                data-select2-id="select2-data-12-vz6w">
                                city 2
                              </option>
                            </select>
                          </div>
                          <div className="text-danger">
                            <ErrorMessage name="city" />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                            <span className="required">Email Address</span>
                          </label>

                          <Field
                            type="email"
                            className="form-control form-control-lg"
                            name="email"
                            placeholder="Email Address"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                            <span className="required">Phone Number</span>
                          </label>

                          <Field
                            type="text"
                            className="form-control form-control-lg"
                            name="phoneNum"
                            placeholder="Phone Number"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="phoneNum" />
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-primary w-100 mb-5">
                          {!loading && (
                            <span className="indicator-label">Save</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      {/* </form> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditCustomerDetails };
