/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

import {
    TableResellers
} from '../../../_metronic/partials/widgets'

const ResellersPage: FC = () => (
    <>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-12'>
            <div className='col-xl-12'>
                <TableResellers className='card-xl-stretch mb-xl-8' />
            </div>
        </div>

        {/* end::Row */}

    </>
)

const ResellersWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Resellers</PageTitle>
            <ResellersPage />
        </>
    )
}

export { ResellersWrapper }