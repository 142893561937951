/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
  imagePath?: string
  innerPadding?: string
  color?: string
}

const EngageWidget2: React.FC<Props> = ({
  className,
  imagePath = '',
  innerPadding = '',
  color = 'primary',
}) => {
  return (
    <div className="row g-10">
        <div className="col-md-7 m-auto">
          <div className="card-xl-stretch me-md-6">
            <a className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales" href="/metronic8/demo1/assets/media/stock/600x400/img-73.jpg">
              <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px d-flex" >
              <img className='symbol m-auto' src={toAbsoluteUrl('/media/svg/brand-logos/adidas.svg')} width="350px" alt='' />
              </div>
              {/* style="background-image:url('/metronic8/demo1/assets/media/stock/600x400/img-73.jpg') */}
             
            </a>
            <div className="m-0 text-center">
            <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3">Fashion</div>
              <div className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">
                Zara White/Blue Floral Puffed up sleeve Dress
              </div>
              <span className="text-primary pe-3 fs-6 text-center mt-5">300.00</span>
              <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5">We’ve been focused on making a the from also not been afraid to and step away been focused create eye.</div>
             

              <button type="submit" className="btn btn-primary px-20" id="">
                <span className="indicator-label">Buy</span>
                <span className="indicator-progress">Please wait... 
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>

              {/* <div className="fs-6 fw-bold">
                <a href="/metronic8/demo1/../demo1/apps/projects/users.html" className="text-gray-700 text-hover-primary">Jane Miller</a>
              
                <span className="text-muted">on Mar 21 2021</span>
              
              </div> */}
            
            </div>
          </div>
        </div>
       
      
      </div>
  )
}

export {EngageWidget2}
