/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { createFullfilmentCenter, queryClient } from '../../../../app/modules/api'
// import { useMutation } from 'react-query'
// import { createFullfilmentCenter } from '../../../modules/api'
// import { login } from '../../../modules/auth/redux/AuthCRUD'
import { useQuery } from '@tanstack/react-query'
import cities from '../../../../app/utils/cities.json';
import barangays from '../../../../app/utils/barangays.json';

const AddPromo: FC = () => {
    const [loading, setLoading] = useState(false);
    
    const NCR = "13" // NCR
    const [city, setCity] = useState('');

    const getBarangays = () => {

        if(city) {
            return barangays.filter(barangay => barangay.city_code === city)
        }

        return [];
    };



    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        address_1: Yup.string().required("This field is required"),
        address_2: Yup.string(),
        state_province: Yup.string().required("This field is required"),
        city_municipality:Yup.string().required("This field is required"),
        barangay_district:Yup.string().required("This field is required"),
        postal_code: Yup.number().required("This field is required").typeError('Postal code should only be numerical'),
        phone_number: Yup.string().required("This field is required").length(11, "Phone number must have 11 digits").matches(/^09.{9}$/, "Phone number should look like '09xxxxxxxxx'"),
        email: Yup.string().required("This field is required").email("Email address is invalid"),
        fc_user_firstname: Yup.string().required("This field is required"),
        fc_user_lastname: Yup.string().required("This field is required"),
        fc_user_email: Yup.string().required("This field is required").email("Email address is invalid"),
        fc_user_username: Yup.string().min(6, "Username must have at least 6 characters").required("This field is required"),
        fc_user_phone_number: Yup.string().required("This field is required").length(11, "Phone number must have 11 digits").matches(/^09.{9}$/, "Phone number should look like '09xxxxxxxxx'"),
        fc_user_password: Yup.string().required("This field is required").min(8, "Password must have at least 8 characters"),
        fc_password_confirmation: Yup.string().required("This field is required").oneOf([Yup.ref('fc_user_password'), null], 'Passwords must match'),
      })

    const formik = useFormik({
        initialValues: {
            name: '',
            address_1: '',
            address_2: '',
            state_province: 'Metro Manila',
            city_municipality:'',
            barangay_district:'',
            postal_code: '',
            phone_number: '',
            email: '',
            fc_user_firstname: '',
            fc_user_lastname: '',
            fc_user_email: '',
            fc_user_username: '',
            fc_user_password: '',
            fc_password_confirmation: '',
            fc_user_phone_number: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const response = window.confirm(
                "Are you sure you want to add this Fulfillment Center?"
               )

               if(response) {
                setLoading(true);
                try {
                    await createFullfilmentCenter(values);
                    
                    queryClient.invalidateQueries(['fc'])
                    formik.resetForm()
                    document.getElementById('close').click()
                    console.log("ASD")
                } catch (err) {
                    if(err?.response?.data?.message) {

                    formik.setFieldError('fc_user_username', err.response.data.message);
                    }
                }
                setLoading(false);
               }

           
        },
      })
      
    return (
        <div className='modal fade' id='kt_modal_add_promos_and_deals' aria-hidden='true'>
            <div className='modal-dialog  modal-fullscreen'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary'  data-bs-dismiss='modal' id="close">
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-20 mx-xl-120 pt-0 pb-15'>
                        <div className='text-left mb-13 pb-5 border-bottom'>
                            <h1 className='mb-3'>Add Fulfillment Center</h1>
                        </div>

                


                        <form id="kt_modal_new_target_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={formik.handleSubmit}>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Fulfillment Center Name</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Fulfillment Center Name" {...formik.getFieldProps('name')} />
                                        {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Address Line 1</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Address Line 1" {...formik.getFieldProps('address_1')} />
                                        {formik.touched.address_1 && formik.errors.address_1 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address_1}</div>
                      </div>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span>Address Line 2</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Address Line 2" {...formik.getFieldProps('address_2')} />

                                        {formik.touched.address_2 && formik.errors.address_2 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address_2}</div>
                      </div>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">State/Province</span>
                                        </label>
                                        <select onSelect={() => {}} className="form-control" placeholder="State/Province" value={"Metro Manila"} >
                                        {["Metro Manila"].map((option, index) => {
                                            return <option key={index} >
                                                {option}
                                            </option>
                                        })}
                                        </select>
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">City/Municipality</span>
                                        </label>
                                        <select
                                        value={formik.values.city_municipality}
                                        onChange={(e) => 
                                            {
                                                const cityIndex = cities.findIndex(c => c.name === e.currentTarget.value);

                                                if(cityIndex > -1) {
                                                    setCity(cities[cityIndex].id)
                                                } else {
                                                    setCity('')
                                                }

                                                formik.setFieldValue('city_municipality', e.currentTarget.value )
                                            }}
                                            className="form-control" placeholder="City/Municipality" >
                                            { [{name: "---SELECT CITY---"}, ...cities]?.sort().map((option, index) => {
                                            return <option key={index} >
                                                {option.name}
                                            </option>
                                        })}
                                        </select>
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Barangay/District</span>
                                        </label>
                                        <select
                                        value={formik.values.barangay_district}
                                        onChange={(e) => 
                                            formik.setFieldValue('barangay_district', e.currentTarget.value )}
                                             className="form-control" placeholder="Barangay/District" >
                                        {getBarangays()?.sort().map((option, index) => {
                        return <option key={index} >
                            {option.name}
                        </option>
                    })}
                                        </select>
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Postal Code</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Postal Code" {...formik.getFieldProps('postal_code')} />
                                                                                {formik.touched.postal_code && formik.errors.postal_code && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.postal_code}</div>
                      </div>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Phone Number</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="+63" {...formik.getFieldProps('phone_number')} />
                                        {formik.touched.phone_number && formik.errors.phone_number && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.phone_number}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Email Address</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Email Address" {...formik.getFieldProps('email')} />
                                        {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.email}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                        <div className='text-left mb-13 pb-5 border-bottom'>
                            <h1 className='mb-3'>Fulfillment Center Facilitator</h1>
                        </div>

                        <div className='row g-6 g-xl-9 '>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">First Name</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="First Name" {...formik.getFieldProps('fc_user_firstname')} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Last Name</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Last Name" {...formik.getFieldProps('fc_user_lastname')} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span>Middle Name</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Middle Name" {...formik.getFieldProps('fc_user_middlename')} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Email Address</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="Email Address" {...formik.getFieldProps('fc_user_email')} />
                                        {formik.touched.fc_user_email && formik.errors.fc_user_email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.fc_user_email}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Phone Number</span>
                                        </label>
                                        <input type="text" className="form-control" placeholder="+63" {...formik.getFieldProps('fc_user_phone_number')} />
                                        {formik.touched.fc_user_phone_number && formik.errors.fc_user_phone_number && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.fc_user_phone_number}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                        <div className='text-left mb-10 pb-4 border-bottom'>
                            <label className="d-flex align-items-center fs-6 fw-bold text-dark">
                                <span>Account Creation</span>
                            </label>
                            <label className="d-flex align-items-center fs-8 mb-2 text-dark">
                                <span>Kindly create username and password for fullment center facilitator account</span>
                            </label>
                        </div>

                        <div className='row g-6 g-xl-9 '>

                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                        <span className="required">Username</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Username" {...formik.getFieldProps('fc_user_username')} />
                                    {formik.touched.fc_user_username && formik.errors.fc_user_username && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.fc_user_username}</div>
                                        </div>
                                        )}
                                </div>
                            </div>
                            </div>

                            <div className='row g-6 g-xl-9 '>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Password</span>
                                        </label>
                                        <input type="password" className="form-control" placeholder="********" {...formik.getFieldProps('fc_user_password')} />
                                        {formik.touched.fc_user_password && formik.errors.fc_user_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.fc_user_password}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2 text-dark">
                                            <span className="required">Confirm Password</span>
                                        </label>
                                        <input type="password" className="form-control" placeholder="********" {...formik.getFieldProps('fc_password_confirmation')} />
                                        {formik.touched.fc_password_confirmation && formik.errors.fc_password_confirmation && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.fc_password_confirmation}</div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button onClick={() => document.getElementById('close').click()} type="reset" id="kt_modal_new_target_cancel" className="btn btn-light-primary me-3">Cancel</button>
                                <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary" disabled={!formik.isValid}>
                                   {!loading ? <span className="indicator-label">Save</span> : <span
                              className="indicator-progress"
                              style={{ display: "block" }}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>} 
                                    
                               
        </button>
                            </div>


                        </form>
                    </div>





                </div>
            </div>
        </div>
    )
}

export { AddPromo }
