/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

import {
    TablePayouts
} from '../../../_metronic/partials/widgets'

const PayoutsPage: FC = () => (
    <>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-12'>
            <div className='col-xl-12'>
                <div className="row g-10">
                    <div className="col-md-9">
                        <TablePayouts className='card-xl-stretch mb-xl-8' />
                    </div>
                    <div className="col-md-3">
                        <div className={`card  card-xl-stretch mb-xl-8`}>

                            <div className='card-body d-flex flex-column'>
                                {/* begin::Items */}
                                <div className='mt-5 px-3'>
                                    <div className='d-flex flex-stack mb-10'>
                                        <div className='me-2'>
                                            <h3 className="card-title align-items-start flex-column mb-3">
                                                <span className="card-label fw-bold fs-6 text-gray-400 mb-3">CURRENT EARNINGS</span>
                                            </h3>
                                            <div>
                                                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                                                        alt=''
                                                        className='h-20px mb-4'
                                                    />
                                                    <span className='fw-bolder fs-1'>  ₱48.2k</span>
                                                </a>
                                                <div className='fs-7 text-muted fw-bold'>As of September 20,2022</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex flex-stack mb-10'>
                                        <div className='me-2'>
                                            <h3 className="card-title align-items-start flex-column mb-3">
                                                <span className="card-label fw-bold fs-6 text-gray-400 mb-3">WITHDRAWN</span>
                                            </h3>
                                            <div>
                                                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                                                        alt=''
                                                        className='h-20px mb-4'
                                                    />       <span className='fw-bolder fs-1'>  ₱48.2k</span>
                                                </a>
                                                <div className='fs-7 text-muted fw-bold'>Total earnings withdrawn</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex flex-stack mb-10'>
                                        <div className='me-2'>
                                            <h3 className="card-title align-items-start flex-column mb-3">
                                                <span className="card-label fw-bold fs-6 text-gray-400 mb-3">PENDING EARNINGS</span>
                                            </h3>
                                            <div>
                                                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                                                        alt=''
                                                        className='h-20px mb-4'
                                                    />       <span className='fw-bolder fs-1'>  ₱48.2k</span>
                                                </a>
                                                <div className='fs-7 text-muted fw-bold'>Pending until December 20,2022</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex flex-stack mb-10'>
                                        <div className='me-2'>
                                            <h3 className="card-title align-items-start flex-column mb-3">
                                                <span className="card-label fw-bold fs-6 text-gray-400 mb-3">APPROVED EARNINGS</span>
                                            </h3>
                                            <div>
                                                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/USAP_I-03.png')}
                                                        alt=''
                                                        className='h-20px mb-4'
                                                    />       <span className='fw-bolder fs-1'>  ₱48.2k</span>
                                                </a>
                                                <div className='fs-7 text-muted fw-bold'>Withdrawable Earnings</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* end::Items */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/* end::Row */}

    </>
)

const PayoutsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Payouts</PageTitle>
            <PayoutsPage />
        </>
    )
}

export { PayoutsWrapper }