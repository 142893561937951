/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../helpers";
import { Link } from "react-router-dom";

const ErrorAlert: FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="modal fade" id="kt_modal_error_message" aria-hidden="true">
      <div className="modal-dialog  modal-dialog-centered mw-480px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal">
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-xl-120 py-5">
            <div className="center px-5" data-kt-subscriptions-form="pricing">
              <div className="card-body p-0">
                <div className="text-center mb-10">
                  <h1 className="text-danger fs-2 mb-10">Ooops!</h1>
                  <div className="text-gray-400 fw-bold fs-7">
                    Some fields are required. Please complete form to submit!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fv-row mb-10">
            <div className="text-center">
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-sm btn-primary w-50 mb-5">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorAlert };
