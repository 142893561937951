/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  MixedWidget8,
  WidgetWelcome,
} from "../../../_metronic/partials/widgets";

const DashboardPage: FC = () => (
  <>
    <WidgetWelcome className=" mb-xl-8 " />

    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      <div className="col-lg-4 col-md-4 col-sm-12 mb-10  align-items-stretch">
        <MixedWidget2
          className="card-xxl-stretch-50 mb-xl-8 h-xl-100"
          chartColor="primary"
          chartHeight="50px"
          strokeColor="#f1fafe"
        />
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 mb-10  align-items-stretch">
        {/* <ListsWidget5 className='card-xxl-stretch' /> */}
        <MixedWidget11
          className="card-xxl-stretch-50 mb-5 mb-xl-8 h-xl-100"
          chartColor="primary"
          chartHeight="175px"
        />
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 mb-10  align-items-stretch">
        <MixedWidget10
          className="card-xxl-stretch-50 mb-5 mb-xl-8 h-xl-100"
          chartColor="primary"
          chartHeight="150px"
        />
      </div>
    </div>
    {/* end::Row */}

    <div className="row g-5 gx-xxl-8">
      <div className="col-xxl-12 xol-xl-12">
        <MixedWidget8
          className="card-xxl-stretch mb-xl-3"
          chartColor="primary"
          chartHeight="150px"
        />
      </div>
    </div>
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
