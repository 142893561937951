/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";

import { TableReports } from "../../../_metronic/partials/widgets";

const ReportsPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className="row gy-5 g-xl-12">
      <div className="col-xl-12">
        <TableReports className="card-xl-stretch mb-xl-8" />
      </div>
    </div>

    {/* end::Row */}
  </>
);

const ReportsWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Reports</PageTitle>
      <ReportsPage />
    </>
  );
};

export { ReportsWrapper };
