import axios from "axios"
import {
  QueryClient,
} from '@tanstack/react-query'
import { doc, updateDoc } from "firebase/firestore/lite"
import { firestore } from "../../../firebase"

const API_URL = process.env.REACT_APP_USAP_API_URL
export const REGISTER_URL = `${API_URL}/business-owner/add-fulfillment-center`
export const EDIT_FC = `${API_URL}/business-owner/update-fulfillment-center`



export function createFullfilmentCenter(params: {
    name: string,
    address_1: string,
    address_2: string,
    state_province: string,
    city_municipality:string,
    barangay_district:string,
    postal_code: string,
    phone_number: string,
    email: string}) {
    return axios.post(REGISTER_URL,params)
  }

  export const editFc = async(params: {
    name: string,
    address_1: string,
    address_2: string,
    state_province: string,
    city_municipality:string,
    barangay_district:string,
    postal_code: string,
    phone_number: string,
    email: string,
  id: string}) =>  {
    
    return axios.post(EDIT_FC, {...params, fc_uid: params.id})
  }

// Create a client
const queryClient = new QueryClient()

export {queryClient}