/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const SendReminder: FC = () => {

    return (
        <div className='modal fade' id='kt_modal_send_reminder' aria-hidden='true'>
            <div className='modal-dialog mw-480px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-left '>
                            <h1 className='mb-3'>Send Reminder</h1>


                        </div>

                        <div className='separator d-flex flex-center mb-8'>
                        </div>


                        <div className='mb-10'>
                            <div className="text-dark">
                                Are you sure you want to send reminder?
                            </div>

                            <div className="mt-5 text-dark">
                                <span className='text-primary'>Arend Pellewer </span>
                                will receive a text message reminder as well as an
                                alert notificationon the USAP mobile app.
                                You can't undo this action.
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="reset" id="kt_modal_new_target_cancel" className="btn btn-light-primary me-3">Cancel</button>
                            <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary">
                                <span className="indicator-label">Send</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
}

export { SendReminder }
