/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { SignUp } from "./components/SignUp";
import { SetPassword } from "./components/SetPassword";
import { LoginBusiness } from "./components/LoginBusiness";
import { OTPVerification } from "./components/OTPVerification";
import { Product } from "./components/Product";
import { ProductPreview } from "./components/ProductPreview";
import { ProductPayment } from "./components/ProductPayment";
import { ProductReceipt } from "./components/ProductReceipt";
import { GuestCheckout } from "./components/GuestCheckout";
import { Login } from "./components/Login";
import { EditCustomerDetails } from "../../../_metronic/partials";
import { ErrorAlert } from "../../../_metronic/partials";
import { EmptyState } from "./components/EmptyState";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <EditCustomerDetails />
      <ErrorAlert />

      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/product-not-available" component={EmptyState} />
        <Route path="/product-not-available" component={EmptyState} />
        <Route path="/auth/registration" component={Registration} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/sign-up" component={SignUp} />
        <Route path="/auth/set-password" component={SetPassword} />
        <Route path="/auth/login-business" component={LoginBusiness} />
        <Route path="/otp-verification" component={OTPVerification} />
        <Route path="/product/:product/:reseller" component={Product} />
        <Route
          path="/product-preview/:product/:reseller/:quantity"
          component={ProductPreview}
        />
        <Route path="/product-payment" component={ProductPayment} />
        <Route path="/product-receipt/:sale_uid" component={ProductReceipt} />
        <Route
          path="/guest-checkout/:product/:reseller/:quantity"
          component={GuestCheckout}
        />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    </>
  );
}
