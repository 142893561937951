/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { useDispatch } from "react-redux";

const HeaderUserMenu: FC = () => {
  // const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(auth.actions.logout());
    localStorage.clear();
  };
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
